// src/components/ScalpingIndicatorChart.js
import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { calculateRSI, calculateMACD, calculateBollingerBands, formatNumber } from '../utils/indicators';

const ScalpingIndicatorChart = ({ data, timeframe, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();
  const [signals, setSignals] = useState([]);

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 400,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: CrosshairMode.Normal },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals)),
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
    });

    const candleSeries = chart.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350',
    });

    // Ensure all data values are numbers
    const numericData = data.map(candle => ({
      time: candle.time,
      open: Number(candle.open),
      high: Number(candle.high),
      low: Number(candle.low),
      close: Number(candle.close),
      volume: Number(candle.volume),
    }));

    candleSeries.setData(numericData);

    // Calculate and set scalping signals
    const newSignals = calculateScalpingSignals(numericData, timeframe);
    setSignals(newSignals);

    // Add markers for buy and sell signals
    candleSeries.setMarkers(
      newSignals.map((signal) => ({
        time: signal.time,
        position: signal.type === 'buy' ? 'belowBar' : 'aboveBar',
        color: signal.type === 'buy' ? '#26a69a' : '#ef5350',
        shape: signal.type === 'buy' ? 'arrowUp' : 'arrowDown',
        text: signal.type === 'buy' ? 'BUY' : 'SELL',
      }))
    );

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, timeframe, szDecimals, isSpot, actualDecimals]);

  const calculateScalpingSignals = (data, timeframe) => {
    const signals = [];
    const lookback = getLookbackPeriod(timeframe);
    const shortLookback = Math.floor(lookback / 2);
  
    try {
      const rsi = calculateRSI(data, 14, szDecimals, isSpot);
      const macd = calculateMACD(data, szDecimals, isSpot, actualDecimals);
      const bollingerBands = calculateBollingerBands(data, 20, 2, szDecimals, isSpot, actualDecimals);
      
      let lastValidRSI = null;
      let lastValidBB = null;
      let lastSignal = null;
      let consecutiveBearishCandles = 0;
      let consecutiveBullishCandles = 0;
  
      for (let i = lookback; i < data.length; i++) {
        const currentCandle = data[i];
        const previousCandle = data[i - 1];
        const previousCandles = data.slice(i - shortLookback, i);
  
        // Handle null values
        const rsiValue = rsi[i] ? parseFloat(rsi[i].value) : lastValidRSI;
        lastValidRSI = rsiValue || lastValidRSI;
  
        const bbData = bollingerBands[i] || lastValidBB;
        lastValidBB = bbData || lastValidBB;
  
        const lowerBand = bbData ? Number(bbData.lower) : null;
        const upperBand = bbData ? Number(bbData.upper) : null;
        const middleBand = bbData ? Number(bbData.middle) : null;
  
        // MACD conditions
        const macdLine = macd.macdLine[i] ? Number(macd.macdLine[i].value) : null;
        const signalLine = macd.signalLine[i] ? Number(macd.signalLine[i].value) : null;
        const macdCrossover = macdLine > signalLine && macd.macdLine[i - 1].value <= macd.signalLine[i - 1].value;
        const macdCrossunder = macdLine < signalLine && macd.macdLine[i - 1].value >= macd.signalLine[i - 1].value;
  
        // Volume analysis
        const averageVolume = calculateAverageVolume(previousCandles);
        const volumeSpike = currentCandle.volume > averageVolume * 1.2;
  
        // Short-term trend analysis
        const ema5 = calculateEMA(data.slice(i - 5, i + 1).map(d => d.close), 5);
        const ema10 = calculateEMA(data.slice(i - 10, i + 1).map(d => d.close), 10);
  
        // Momentum
        const roc = (currentCandle.close - data[i - 3].close) / data[i - 3].close * 100;
  
        // Dynamic RSI thresholds
        const rsiLowerThreshold = 40 - (Math.abs(roc) / 2);
        const rsiUpperThreshold = 60 + (Math.abs(roc) / 2);
  
        // Count consecutive bearish/bullish candles
        if (currentCandle.close < previousCandle.close) {
          consecutiveBearishCandles++;
          consecutiveBullishCandles = 0;
        } else if (currentCandle.close > previousCandle.close) {
          consecutiveBullishCandles++;
          consecutiveBearishCandles = 0;
        } else {
          consecutiveBearishCandles = 0;
          consecutiveBullishCandles = 0;
        }
  
        // Buy signal conditions
        if ((rsiValue < rsiLowerThreshold || currentCandle.close < lowerBand) && 
            (macdCrossover || macdLine > signalLine) && 
            (volumeSpike || ema5 > ema10) && 
            roc < -1 &&
            lastSignal !== 'buy') {
          signals.push({ time: currentCandle.time, type: 'buy' });
          lastSignal = 'buy';
          consecutiveBearishCandles = 0;
        }
        // Sell signal conditions
        else if ((rsiValue > rsiUpperThreshold || currentCandle.close > upperBand) && 
                 (macdCrossunder || macdLine < signalLine) && 
                 (volumeSpike || ema5 < ema10) && 
                 roc > 1 &&
                 lastSignal !== 'sell') {
          signals.push({ time: currentCandle.time, type: 'sell' });
          lastSignal = 'sell';
          consecutiveBullishCandles = 0;
        }
        
        // Trend reversal detection
        const priceChange = (currentCandle.close - data[i - shortLookback].close) / data[i - shortLookback].close * 100;
        if (lastSignal === 'sell' && priceChange > 3 && ema5 > ema10 && rsiValue > 50) {
          signals.push({ time: currentCandle.time, type: 'buy' });
          lastSignal = 'buy';
        } else if (lastSignal === 'buy' && priceChange < -3 && ema5 < ema10 && rsiValue < 50) {
          signals.push({ time: currentCandle.time, type: 'sell' });
          lastSignal = 'sell';
        }
      }
    } catch (error) {
      console.error('Error calculating scalping signals:', error);
      console.error('Error details:', error.message);
      console.error('Stack trace:', error.stack);
    }
  
    return signals;
  };
  
  // Helper function to calculate Exponential Moving Average
  const calculateEMA = (data, period) => {
    const k = 2 / (period + 1);
    let ema = data[0];
    for (let i = 1; i < data.length; i++) {
      ema = data[i] * k + ema * (1 - k);
    }
    return ema;
  };
  
  // Helper function to calculate average volume
  const calculateAverageVolume = (candles) => {
    const sum = candles.reduce((acc, candle) => acc + Number(candle.volume), 0);
    return sum / candles.length;
  };

  const getLookbackPeriod = (timeframe) => {
    switch (timeframe) {
      case '1m': return 60;
      case '5m': return 48;
      case '15m': return 32;
      case '30m': return 24;
      case '1h': return 16;
      case '4h': return 12;
      case '1d': return 10;
      default: return 30;
    }
  };

  return (
    <div>
      <div ref={chartContainerRef} />
      <div className="mt-4">
        <h3 className="text-lg font-semibold">Scalping Signals</h3>
        <p>Total Signals: {signals.length}</p>
        <p>Buy Signals: {signals.filter(s => s.type === 'buy').length}</p>
        <p>Sell Signals: {signals.filter(s => s.type === 'sell').length}</p>
        <p className="text-sm text-gray-600 mt-2">
          This indicator combines RSI, MACD, Bollinger Bands, volume analysis, and trend analysis for more accurate scalping signals.
          Always conduct your own research and risk assessment before making trading decisions.
        </p>
      </div>
    </div>
  );
};

export default ScalpingIndicatorChart;