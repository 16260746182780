// src/pages/LandingPage.js
import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <div className="max-w-2xl mx-auto text-center">
        <h1 className="text-4xl font-bold mb-4">Welcome to Crypn</h1>
        <p className="text-xl mb-8">
          Crypn is a powerful cryptocurrency analytics platform that provides real-time market data, advanced charting tools, and comprehensive trading insights. Whether you're a seasoned trader or just getting started, Crypn offers the tools you need to make informed decisions in the crypto market.
        </p>
        <Link
          to="/dashboard"
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Start App
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;