// indicators.js

export const formatNumber = (number, szDecimals, isSpot) => {
  if (typeof number !== 'number' || isNaN(number)) return null;
  const maxDecimals = isSpot ? 8 : 6;
  const allowedDecimals = Math.min(Math.max(0, maxDecimals - (szDecimals || 0)), 100);
  return Number(Number(number).toFixed(allowedDecimals));
};

export const getTimeframeInMilliseconds = (tf) => {
  const units = {
    'm': 60 * 1000,
    'h': 60 * 60 * 1000,
    'd': 24 * 60 * 60 * 1000
  };
  const [value, unit] = tf.match(/(\d+)(\w)/).slice(1);
  return parseInt(value) * units[unit];
};

export const calculateIndicators = (data, szDecimals, isSpot) => {
  if (!data || data.length === 0) {
    console.error('No data available for indicator calculation');
    return {};
  }

  // Add error checking for each indicator calculation
  const macdData = safeCalculate(() => calculateMACD(data, szDecimals, isSpot), 'MACD');
  const rsi = safeCalculate(() => calculateRSI(data, szDecimals, isSpot), 'RSI');
  const stochRSIData = safeCalculate(() => calculateStochRSI(data, szDecimals, isSpot), 'Stochastic RSI');
  const dmiData = safeCalculate(() => calculateDMI(data, szDecimals, isSpot), 'DMI');
  const fibonacciLevels = safeCalculate(() => calculateFibonacci(data, szDecimals, isSpot), 'Fibonacci');
  const supportResistanceLevels = safeCalculate(() => calculateSupportResistance(data, szDecimals, isSpot), 'Support/Resistance');
  const vwapData = safeCalculate(() => calculateVWAP(data, szDecimals, isSpot), 'VWAP');
  const bollingerBandsData = safeCalculate(() => calculateBollingerBands(data, 20, 2, szDecimals, isSpot), 'Bollinger Bands');
  const tdSequentialData = safeCalculate(() => calculateTDSequential(data), 'TD Sequential');

  const channelPatternData = safeCalculate(() => calculateChannelPattern(data), 'Channel Pattern');
  const liquiditySwingsData = safeCalculate(() => analyzeLiquiditySwings(data, data.map(d => d.volume)), 'Liquidity Swings');
  const divergenceData = safeCalculate(() => detectDivergence(data, { rsi, macd: macdData }), 'Divergence');

  const stochasticData = safeCalculate(() => calculateStochastic(data), 'Stochastic');
  const obvData = safeCalculate(() => calculateOBV(data), 'OBV');
  const ichimokuData = safeCalculate(() => calculateIchimokuCloud(data), 'Ichimoku Cloud');
  const atrData = safeCalculate(() => calculateATR(data), 'ATR');

  const alignIndicatorData = (candleData, indicatorData) => {
    if (!indicatorData || indicatorData.length === 0) return [];
    const alignedData = [];
    let indicatorIndex = 0;
  
    for (let i = 0; i < candleData.length; i++) {
      if (indicatorIndex < indicatorData.length && candleData[i].time === indicatorData[indicatorIndex].time) {
        alignedData.push(indicatorData[indicatorIndex]);
        indicatorIndex++;
      } else {
        alignedData.push({ time: candleData[i].time, value: null });
      }
    }
  
    return alignedData;
  };

  const alignedRSI = alignIndicatorData(data, rsi);

  const ma5 = safeCalculate(() => calculateMA(data, 5, szDecimals, isSpot), 'MA5');
  const ma10 = safeCalculate(() => calculateMA(data, 10, szDecimals, isSpot), 'MA10');
  const ma20 = safeCalculate(() => calculateMA(data, 20, szDecimals, isSpot), 'MA20');
  const ma50 = safeCalculate(() => calculateMA(data, 50, szDecimals, isSpot), 'MA50');
  const ma100 = safeCalculate(() => calculateMA(data, 100, szDecimals, isSpot), 'MA100');
  const ma200 = safeCalculate(() => calculateMA(data, 200, szDecimals, isSpot), 'MA200');

  return {
    macd: macdData,
    rsi: alignedRSI,
    stochRSI: stochRSIData,
    dmi: dmiData,
    fibonacci: fibonacciLevels,
    supportResistance: supportResistanceLevels,
    vwap: vwapData,
    bollingerBands: bollingerBandsData,
    tdSequential: tdSequentialData,
    ma: {
      ma5,
      ma10,
      ma20,
      ma50,
      ma100,
      ma200
    },
    channelPattern: channelPatternData,
    liquiditySwings: liquiditySwingsData,
    divergence: divergenceData,
    stochastic: stochasticData,
    obv: obvData,
    ichimoku: ichimokuData,
    atr: atrData
  };
};

// Helper function to safely calculate indicators
const safeCalculate = (calcFunction, indicatorName) => {
  try {
    return calcFunction();
  } catch (error) {
    console.error(`Error calculating ${indicatorName}:`, error);
    return null;
  }
};

export const calculateMACD = (data, szDecimals, isSpot, actualDecimals) => {
  const shortPeriod = 12;
  const longPeriod = 26;
  const signalPeriod = 9;
  
  const getEMA = (period, values, smoothing = 2) => {
    const ema = [values[0]];
    const k = smoothing / (period + 1);
    for (let i = 1; i < values.length; i++) {
      ema.push(values[i] * k + ema[i - 1] * (1 - k));
    }
    return ema;
  };

  const closePrices = data.map(d => d.close);
  const shortEMA = getEMA(shortPeriod, closePrices);
  const longEMA = getEMA(longPeriod, closePrices);

  const macdLine = longEMA.map((long, i) => ({
    time: data[i].time,
    value: Number((shortEMA[i] - long).toFixed(Math.max(szDecimals, actualDecimals, 5)))
  }));

  const signalLine = getEMA(signalPeriod, macdLine.map(d => d.value)).map((signal, i) => ({
    time: data[i].time,
    value: Number(signal.toFixed(Math.max(szDecimals, actualDecimals, 5)))
  }));

  const histogram = macdLine.map((macd, i) => ({
    time: data[i].time,
    value: Number((macd.value - signalLine[i].value).toFixed(Math.max(szDecimals, actualDecimals, 5))),
    color: macd.value >= signalLine[i].value ? 'green' : 'red'
  }));

  return { macdLine, signalLine, histogram };
};

export const calculateRSI = (data, szDecimals, isSpot) => {
  const period = 14;
  const rsi = [];
  let avgGain = 0;
  let avgLoss = 0;

  for (let i = 1; i < data.length; i++) {
    const change = data[i].close - data[i - 1].close;
    if (i <= period) {
      avgGain += Math.max(change, 0) / period;
      avgLoss += Math.abs(Math.min(change, 0)) / period;
      if (i === period) {
        const rs = avgGain / avgLoss;
        const rsiValue = 100 - (100 / (1 + rs));
        rsi.push({
          time: data[i].time,
          value: formatNumber(rsiValue, szDecimals, isSpot)
        });
      }
    } else {
      avgGain = (avgGain * (period - 1) + Math.max(change, 0)) / period;
      avgLoss = (avgLoss * (period - 1) + Math.abs(Math.min(change, 0))) / period;
      const rs = avgGain / avgLoss;
      const rsiValue = 100 - (100 / (1 + rs));
      rsi.push({
        time: data[i].time,
        value: formatNumber(rsiValue, szDecimals, isSpot)
      });
    }
  }

  return rsi;
};

export const calculateDMI = (data, szDecimals, isSpot) => {
  const period = 14;
  const smoothingPeriod = 14;
  const diPlus = [];
  const diMinus = [];
  const adx = [];
  let tr = 0;
  let dmPlus = 0;
  let dmMinus = 0;

  for (let i = 1; i < data.length; i++) {
    const high = data[i].high;
    const low = data[i].low;
    const prevHigh = data[i - 1].high;
    const prevLow = data[i - 1].low;
    const prevClose = data[i - 1].close;

    const trueRange = Math.max(
      high - low,
      Math.abs(high - prevClose),
      Math.abs(low - prevClose)
    );

    const plusDM = Math.max(high - prevHigh, 0);
    const minusDM = Math.max(prevLow - low, 0);

    if (i <= period) {
      tr += trueRange;
      dmPlus += plusDM;
      dmMinus += minusDM;
      if (i === period) {
        const diPlusValue = 100 * dmPlus / tr;
        const diMinusValue = 100 * dmMinus / tr;
        diPlus.push({ time: data[i].time, value: formatNumber(diPlusValue, szDecimals, isSpot) });
        diMinus.push({ time: data[i].time, value: formatNumber(diMinusValue, szDecimals, isSpot) });
        adx.push({ time: data[i].time, value: formatNumber(100 * Math.abs(diPlusValue - diMinusValue) / (diPlusValue + diMinusValue), szDecimals, isSpot) });
      }
    } else {
      tr = tr - tr / period + trueRange;
      dmPlus = dmPlus - dmPlus / period + plusDM;
      dmMinus = dmMinus - dmMinus / period + minusDM;

      const diPlusValue = 100 * dmPlus / tr;
      const diMinusValue = 100 * dmMinus / tr;
      diPlus.push({ time: data[i].time, value: formatNumber(diPlusValue, szDecimals, isSpot) });
      diMinus.push({ time: data[i].time, value: formatNumber(diMinusValue, szDecimals, isSpot) });

      const dx = 100 * Math.abs(diPlusValue - diMinusValue) / (diPlusValue + diMinusValue);
      if (adx.length < smoothingPeriod) {
        adx.push({ time: data[i].time, value: formatNumber(dx, szDecimals, isSpot) });
      } else {
        const prevADX = adx[adx.length - 1].value;
        adx.push({ time: data[i].time, value: formatNumber((prevADX * (smoothingPeriod - 1) + dx) / smoothingPeriod, szDecimals, isSpot) });
      }
    }
  }

  return { diPlus, diMinus, adx };
};

export const calculateFibonacci = (data, szDecimals, isSpot) => {
  const high = Math.max(...data.map(d => d.high));
  const low = Math.min(...data.map(d => d.low));
  const diff = high - low;

  return [
    { level: 0, value: formatNumber(high, szDecimals, isSpot) },
    { level: 0.236, value: formatNumber(high - 0.236 * diff, szDecimals, isSpot) },
    { level: 0.382, value: formatNumber(high - 0.382 * diff, szDecimals, isSpot) },
    { level: 0.5, value: formatNumber(high - 0.5 * diff, szDecimals, isSpot) },
    { level: 0.618, value: formatNumber(high - 0.618 * diff, szDecimals, isSpot) },
    { level: 1, value: formatNumber(low, szDecimals, isSpot) }
  ];
};

export const calculateSupportResistance = (data, szDecimals, isSpot) => {
  const levels = [];
  const threshold = 0.02; // 2% threshold

  for (let i = 1; i < data.length - 1; i++) {
    if (
      (data[i].high > data[i - 1].high && data[i].high > data[i + 1].high) ||
      (data[i].low < data[i - 1].low && data[i].low < data[i + 1].low)
    ) {
      const level = data[i].high > data[i - 1].high ? data[i].high : data[i].low;
      if (!levels.some(l => Math.abs(l - level) / level < threshold)) {
        levels.push(formatNumber(level, szDecimals, isSpot));
      }
    }
  }

  const currentPrice = data[data.length - 1].close;
  const support = levels.filter(level => level < currentPrice).sort((a, b) => b - a);
  const resistance = levels.filter(level => level > currentPrice).sort((a, b) => a - b);

  return { support, resistance };
};

export const calculateVWAP = (data, szDecimals, isSpot) => {
  let cumulativeTPV = 0;
  let cumulativeVolume = 0;

  return data.map(candle => {
    const typicalPrice = (candle.high + candle.low + candle.close) / 3;
    const TPV = typicalPrice * candle.volume;
    cumulativeTPV += TPV;
    cumulativeVolume += candle.volume;
    return {
      time: candle.time,
      value: formatNumber(cumulativeTPV / cumulativeVolume, szDecimals, isSpot)
    };
  });
};

export const calculateMA = (data, period, szDecimals, isSpot) => {
  const ma = [];
  if (!data || !Array.isArray(data) || data.length === 0) {
    console.warn('Invalid or empty data passed to calculateMA');
    return ma;
  }
  
  for (let i = period - 1; i < data.length; i++) {
    const slice = data.slice(i - period + 1, i + 1);
    if (slice.length < period) continue;
    
    const sum = slice.reduce((acc, candle) => acc + (candle?.close || 0), 0);
    const value = formatNumber(sum / period, szDecimals, isSpot);
    if (value !== null && data[i] && data[i].time) {
      ma.push({
        time: data[i].time,
        value: value
      });
    }
  }
  return ma;
};

export const detectMACDCrossover = (macdData, candleData, timeframe) => {
  if (!macdData || !macdData.macdLine || !macdData.signalLine || macdData.macdLine.length === 0 || macdData.signalLine.length === 0) {
    return { signal: '', time: null, timeSinceCrossover: null };
  }

  let crossoverIndex = -1;
  for (let i = macdData.macdLine.length - 1; i > 0; i--) {
    if (
      (macdData.macdLine[i].value > macdData.signalLine[i].value && macdData.macdLine[i-1].value <= macdData.signalLine[i-1].value) ||
      (macdData.macdLine[i].value < macdData.signalLine[i].value && macdData.macdLine[i-1].value >= macdData.signalLine[i-1].value)
    ) {
      crossoverIndex = i;
      break;
    }
  }

  if (crossoverIndex === -1) {
    return { signal: '', time: null, timeSinceCrossover: null };
  }

  const signal = macdData.macdLine[crossoverIndex].value > macdData.signalLine[crossoverIndex].value ? 'Bullish' : 'Bearish';
  const crossoverTime = macdData.macdLine[crossoverIndex].time * 1000; // Convert to milliseconds
  const currentTime = Date.now();
  const timeSinceCrossover = currentTime - crossoverTime;

  return { signal, time: new Date(crossoverTime), timeSinceCrossover };
};

export const getLatestIndicatorValues = (indicators) => {
  if (!indicators) {
    console.warn('Indicators object is undefined');
    return {};
  }

  const getLastValue = (arr) => {
    if (Array.isArray(arr) && arr.length > 0) {
      return arr[arr.length - 1]?.value ?? null;
    }
    return null;
  };

  const getMacdValues = (macd) => {
    if (!macd) return { macd: null, signal: null, histogram: null };
    return {
      macd: getLastValue(macd.macdLine),
      signal: getLastValue(macd.signalLine),
      histogram: getLastValue(macd.histogram)
    };
  };

  const getDmiValues = (dmi) => {
    if (!dmi) return { diPlus: null, diMinus: null, adx: null };
    return {
      diPlus: getLastValue(dmi.diPlus),
      diMinus: getLastValue(dmi.diMinus),
      adx: getLastValue(dmi.adx)
    };
  };

  const getMaValues = (ma) => {
    if (!ma) return { ma5: null, ma10: null, ma20: null, ma50: null, ma100: null, ma200: null };
    return {
      ma5: getLastValue(ma.ma5),
      ma10: getLastValue(ma.ma10),
      ma20: getLastValue(ma.ma20),
      ma50: getLastValue(ma.ma50),
      ma100: getLastValue(ma.ma100),
      ma200: getLastValue(ma.ma200)
    };
  };

  return {
    rsi: getLastValue(indicators.rsi),
    macd: getMacdValues(indicators.macd),
    dmi: getDmiValues(indicators.dmi),
    vwap: getLastValue(indicators.vwap),
    ma: getMaValues(indicators.ma),
    fibonacci: indicators.fibonacci || [],
    supportResistance: indicators.supportResistance || { support: [], resistance: [] },
    bollingerBands: indicators.bollingerBands ? indicators.bollingerBands[indicators.bollingerBands.length - 1] : null,
    tdSequential: indicators.tdSequential ? indicators.tdSequential[indicators.tdSequential.length - 1] : null,
    channelPattern: indicators.channelPattern || { upperChannel: [], lowerChannel: [] },
    liquiditySwings: indicators.liquiditySwings ? indicators.liquiditySwings[indicators.liquiditySwings.length - 1] : null,
    divergence: indicators.divergence ? indicators.divergence[indicators.divergence.length - 1] : null
  };
};
  
export const getMarketTrend = (trades) => {
  if (trades.length === 0) return 'Neutral';
  
  const buyCount = trades.filter(trade => trade.side === 'B').length;
  const sellCount = trades.length - buyCount;
  
  if (buyCount > sellCount) return 'Bullish';
  if (sellCount > buyCount) return 'Bearish';
  return 'Neutral';
};

export const getMarketTrendColor = (marketTrend) => {
  if (marketTrend === 'Bullish') return 'text-green-600';
  if (marketTrend === 'Bearish') return 'text-red-600';
  return 'text-gray-600';
};

export const formatTradeSide = (side) => {
  return side === 'B' ? 'BUY' : 'SELL';
};

export const calculatePercentageChange = (oldValue, newValue) => {
  return ((newValue - oldValue) / oldValue) * 100;
};

export const isNearLevel = (price, level, threshold = 0.01) => {
  return Math.abs(price - level) / level < threshold;
};

export const getTrendStrength = (adx) => {
  if (adx < 20) return 'Weak';
  if (adx < 40) return 'Moderate';
  if (adx < 60) return 'Strong';
  return 'Very Strong';
};

export const getRSICondition = (rsi) => {
  if (rsi > 70) return 'Overbought';
  if (rsi < 30) return 'Oversold';
  return 'Neutral';
};

export const getMACDSignal = (macd, signal) => {
  if (macd > signal) return 'Bullish';
  if (macd < signal) return 'Bearish';
  return 'Neutral';
};

export const calculateATR = (data, period = 14, szDecimals, isSpot) => {
  const tr = data.map((d, i) => {
    if (i === 0) return d.high - d.low;
    const yesterdayClose = data[i - 1].close;
    return Math.max(
      d.high - d.low,
      Math.abs(d.high - yesterdayClose),
      Math.abs(d.low - yesterdayClose)
    );
  });

  const atr = [];
  let sum = tr.slice(0, period).reduce((a, b) => a + b, 0);
  atr.push({ time: data[period - 1].time, value: formatNumber(sum / period, szDecimals, isSpot) });

  for (let i = period; i < data.length; i++) {
    sum = (atr[atr.length - 1].value * (period - 1) + tr[i]) / period;
    atr.push({ time: data[i].time, value: formatNumber(sum, szDecimals, isSpot) });
  }

  return atr;
};

export const calculateBollingerBands = (data, period = 20, stdDev = 2, szDecimals, isSpot) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    console.warn('Invalid or empty data passed to calculateBollingerBands');
    return [];
  }

  const ma = calculateMA(data, period, szDecimals, isSpot);
  if (ma.length === 0) return [];
  
  const bands = ma.map((d, i) => {
    if (i + period > data.length) return null;
    const slice = data.slice(i, i + period);
    if (slice.length < period) return null;
    
    const sum = slice.reduce((sum, price) => sum + Math.pow((price?.close || 0) - d.value, 2), 0);
    const std = Math.sqrt(sum / period);
    const upper = formatNumber(d.value + stdDev * std, szDecimals, isSpot);
    const lower = formatNumber(d.value - stdDev * std, szDecimals, isSpot);
    
    if (upper !== null && lower !== null) {
      return {
        time: d.time,
        middle: d.value,
        upper: upper,
        lower: lower
      };
    }
    return null;
  }).filter(band => band !== null);

  return bands;
};

export const calculateStochRSI = (data, period = 14, smoothK = 3, smoothD = 3, szDecimals, isSpot) => {
  const rsi = calculateRSI(data, szDecimals, isSpot);
  const stochRSI = [];
  const k = [];
  const d = [];

  for (let i = period; i < rsi.length; i++) {
    const slicedRSI = rsi.slice(i - period + 1, i + 1);
    const highRSI = Math.max(...slicedRSI.map(d => d.value));
    const lowRSI = Math.min(...slicedRSI.map(d => d.value));
    
    const stochRSIValue = (rsi[i].value - lowRSI) / (highRSI - lowRSI);
    stochRSI.push({ time: rsi[i].time, value: stochRSIValue });

    if (stochRSI.length >= smoothK) {
      const kValue = stochRSI.slice(-smoothK).reduce((sum, d) => sum + d.value, 0) / smoothK;
      k.push({ time: rsi[i].time, value: formatNumber(kValue * 100, szDecimals, isSpot) });

      if (k.length >= smoothD) {
        const dValue = k.slice(-smoothD).reduce((sum, d) => sum + d.value, 0) / smoothD;
        d.push({ time: rsi[i].time, value: formatNumber(dValue, szDecimals, isSpot) });
      }
    }
  }

  return { k, d };
};

export const calculateTDSequential = (data) => {
  let setupCount = 0;
  let setupType = null;
  const tdSequential = [];

  for (let i = 0; i < data.length; i++) {
    if (i < 4) {
      tdSequential.push({ time: data[i].time, value: null, setup: null });
      continue;
    }

    const currentClose = data[i].close;
    const fourCandlesAgoClose = data[i - 4].close;

    if (setupType === 'bullish' || setupType === null) {
      if (currentClose < fourCandlesAgoClose) {
        setupCount++;
        setupType = 'bullish';
      } else {
        setupCount = 0;
        setupType = null;
      }
    }

    if (setupType === 'bearish' || setupType === null) {
      if (currentClose > fourCandlesAgoClose) {
        setupCount++;
        setupType = 'bearish';
      } else {
        setupCount = 0;
        setupType = null;
      }
    }

    if (setupCount > 0 && setupCount <= 9) {
      tdSequential.push({ time: data[i].time, value: setupCount, setup: setupType });
    } else if (setupCount > 9) {
      tdSequential.push({ time: data[i].time, value: setupCount, setup: `${setupType} (Countdown)` });
    } else {
      tdSequential.push({ time: data[i].time, value: null, setup: null });
    }

    if (setupCount === 13) {
      setupCount = 0;
      setupType = null;
    }
  }

  return tdSequential;
};

export const generateAISentiment = (candleData, indicators, coinStats, orderBook, recentTrades, timeframe, szDecimals, isSpot) => {
  if (!candleData || candleData.length === 0) {
    console.error('No candle data available for AI sentiment generation');
    return null;
  }

  const lastCandle = candleData[candleData.length - 1];
  const lastPrice = lastCandle.close;

  const sentiment = {
    currentPrice: formatNumber(lastPrice, szDecimals, isSpot),
    priceForecast: calculatePriceForecast(candleData, indicators, timeframe, szDecimals, isSpot),
    tradingRecommendation: generateTradingRecommendation(candleData, indicators, coinStats, orderBook, recentTrades, timeframe, szDecimals, isSpot),
    marketSentiment: analyzeMarketSentiment(indicators, lastPrice, szDecimals, isSpot),
    additionalInsights: generateAdditionalInsights(candleData, indicators, coinStats, orderBook, recentTrades, szDecimals, isSpot),
  };

  return sentiment;
};

export const calculatePriceForecast = (candleData, indicators, timeframe, szDecimals, isSpot) => {
  if (!candleData || candleData.length === 0 || !indicators) {
    console.error('Insufficient data for price forecast calculation');
    return null;
  }

  const lastPrice = candleData[candleData.length - 1].close;
  const rsi = indicators.rsi[indicators.rsi.length - 1].value;
  const macd = indicators.macd.macdLine[indicators.macd.macdLine.length - 1].value;
  const signal = indicators.macd.signalLine[indicators.macd.signalLine.length - 1].value;
  const bb = indicators.bollingerBands[indicators.bollingerBands.length - 1];
  const atr = calculateATR(candleData, 14, szDecimals, isSpot);
  const lastATR = atr[atr.length - 1].value;

  // Calculate historical volatility
  const returns = candleData.slice(1).map((candle, i) => 
    Math.log(candle.close / candleData[i].close)
  );
  const volatility = Math.sqrt(returns.reduce((sum, ret) => sum + ret * ret, 0) / returns.length) * Math.sqrt(252);

  // Calculate trend strength
  const trendStrength = (macd - signal) / signal;

  // Calculate momentum
  const momentum = (lastPrice - candleData[candleData.length - 20].close) / candleData[candleData.length - 20].close;

  // Calculate price pressure
  const pricePressure = (lastPrice - bb.middle) / (bb.upper - bb.lower);

  // Combine factors for forecast
  const forecastFactor = (trendStrength + momentum + pricePressure) / 3;

  // Adjust forecast based on RSI
  const rsiAdjustment = (50 - rsi) / 100; // Negative when overbought, positive when oversold

  // Calculate forecasts
  const calculateForecast = (days) => {
    const timeAdjustment = Math.sqrt(days);
    return formatNumber(lastPrice * (1 + (forecastFactor + rsiAdjustment) * volatility * timeAdjustment), szDecimals, isSpot);
  };

  const day1Forecast = calculateForecast(1);
  const day2Forecast = calculateForecast(2);
  const day5Forecast = calculateForecast(5);

  // Calculate accuracy based on multiple factors
  const calculateAccuracy = (days) => {
    const baseAccuracy = 0.85;
    const trendAccuracy = Math.min(Math.abs(trendStrength), 0.1);
    const volatilityPenalty = Math.min(volatility, 0.1);
    const timePenalty = 0.01 * days;
    const priceChangePenalty = Math.abs((calculateForecast(days) - lastPrice) / lastPrice) * 0.5;
    
    return Math.max(0.5, Math.min(baseAccuracy + trendAccuracy - volatilityPenalty - timePenalty - priceChangePenalty, 0.99));
  };
  
  return {
    day1: day1Forecast,
    day2: day2Forecast,
    day5: day5Forecast,
    day1Accuracy: calculateAccuracy(1),
    day2Accuracy: calculateAccuracy(2),
    day5Accuracy: calculateAccuracy(5),
  };
};

export const generateTradingRecommendation = (candleData, indicators, coinStats, orderBook, recentTrades, timeframe, szDecimals, isSpot) => {
  const lastPrice = candleData[candleData.length - 1].close;
  const rsi = indicators.rsi[indicators.rsi.length - 1].value;
  const macd = indicators.macd.macdLine[indicators.macd.macdLine.length - 1].value;
  const signal = indicators.macd.signalLine[indicators.macd.signalLine.length - 1].value;
  const bb = indicators.bollingerBands[indicators.bollingerBands.length - 1];
  const tdSequential = indicators.tdSequential[indicators.tdSequential.length - 1];
  const dmi = indicators.dmi;
  const adx = dmi.adx[dmi.adx.length - 1].value;

  let action = 'HOLD';
  let confidence = 0.5;
  let potentialROI = 0;
  let entryTime = null;
  let exitTime = null;

  // Determine action and confidence
  if (rsi < 30 && macd > signal && lastPrice < bb.lower) {
    action = 'BUY';
    confidence = 0.8;
  } else if (rsi > 70 && macd < signal && lastPrice > bb.upper) {
    action = 'SELL';
    confidence = 0.8;
  }

  // Adjust confidence based on ADX
  if (adx > 25) {
    confidence = Math.min(confidence + 0.1, 1);
  } else if (adx < 20) {
    confidence = Math.max(confidence - 0.1, 0);
  }

  // Calculate potential ROI and entry/exit times
  if (action !== 'HOLD') {
    const currentTime = new Date();
    const timeframeInHours = getTimeframeInHours(timeframe);
    
    // Predict entry and exit times based on TD Sequential
    const tdValue = tdSequential.value;
    const entryOffset = Math.max(9 - tdValue, 1); // Enter when TD Sequential completes or immediately if past 9
    const exitOffset = entryOffset + 4; // Exit 4 periods after entry

    entryTime = new Date(currentTime.getTime() + entryOffset * timeframeInHours * 60 * 60 * 1000);
    exitTime = new Date(currentTime.getTime() + exitOffset * timeframeInHours * 60 * 60 * 1000);

    // Calculate potential ROI
    const forecastedPrice = calculatePriceForecast(candleData, indicators, timeframe, szDecimals, isSpot).day5;
    potentialROI = action === 'BUY' ? (forecastedPrice - lastPrice) / lastPrice : (lastPrice - forecastedPrice) / lastPrice;
  }

  return {
    action,
    entryTime: action !== 'HOLD' ? entryTime : 'N/A',
    exitTime: action !== 'HOLD' ? exitTime : 'N/A',
    potentialROI: action !== 'HOLD' ? formatNumber(potentialROI, szDecimals, isSpot) : 'N/A',
    confidence: formatNumber(confidence, 2, true),
  };
};

const getTimeframeInHours = (timeframe) => {
  switch(timeframe) {
    case '1h': return 1;
    case '2h': return 2;
    case '4h': return 4;
    case '8h': return 8;
    case '12h': return 12;
    case '1d': return 24;
    case '3d': return 72;
    case '1w': return 168;
    case '1M': return 730;
    default: return 1;
  }
};

export const analyzeMarketSentiment = (indicators, lastPrice, szDecimals, isSpot) => {
  const lastTDSequential = indicators.tdSequential[indicators.tdSequential.length - 1];
  return {
    RSI: getRSICondition(indicators.rsi[indicators.rsi.length - 1].value),
    MACD: getMACDSignal(indicators.macd.macdLine[indicators.macd.macdLine.length - 1].value, indicators.macd.signalLine[indicators.macd.signalLine.length - 1].value),
    BollingerBands: getBollingerBandsSignal(lastPrice, indicators.bollingerBands[indicators.bollingerBands.length - 1]),
    DMI: getDMISignal(indicators.dmi),
    TDSequential: lastTDSequential,
    VWAP: getVWAPSignal(lastPrice, indicators.vwap[indicators.vwap.length - 1].value),
    ChannelPattern: getChannelPatternSignal(lastPrice, indicators.channelPattern),
    LiquiditySwings: getLiquiditySwingsSignal(indicators.liquiditySwings[indicators.liquiditySwings.length - 1]),
    Divergence: getDivergenceSignal(indicators.divergence[indicators.divergence.length - 1])
  };
};

const getChannelPatternSignal = (price, channelPattern) => {
  const { upperChannel, lowerChannel } = channelPattern;
  const latestUpperChannel = upperChannel[upperChannel.length - 1].value;
  const latestLowerChannel = lowerChannel[lowerChannel.length - 1].value;

  if (price > latestUpperChannel) return 'Above channel';
  if (price < latestLowerChannel) return 'Below channel';
  return 'Within channel';
};

const getLiquiditySwingsSignal = (latestSwing) => {
  if (!latestSwing) return 'No recent liquidity swing';
  return `Recent ${latestSwing.type} liquidity swing`;
};

const getDivergenceSignal = (latestDivergence) => {
  if (!latestDivergence) return 'No recent divergence';
  return `${latestDivergence.type} divergence on ${latestDivergence.indicator}`;
};

export const generateAdditionalInsights = (candleData, indicators, coinStats, orderBook, recentTrades, szDecimals, isSpot) => {
  const insights = [];
  if (!candleData || candleData.length === 0) {
    console.warn('No candle data available for additional insights');
    return insights;
  }
  const lastPrice = candleData[candleData.length - 1].close;

  // Fibonacci levels insight
  if (indicators.fibonacci && indicators.fibonacci.length > 0) {
    const fibLevels = indicators.fibonacci;
    const nearestFibLevel = findNearestFibonacciLevel(lastPrice, fibLevels);
    if (nearestFibLevel) {
      insights.push(`The current price is near the ${nearestFibLevel.level.toFixed(3)} Fibonacci retracement level at $${formatNumber(nearestFibLevel.value, szDecimals, isSpot)}.`);
    }
  }

  // Support and resistance levels insight
  if (indicators.supportResistance && indicators.supportResistance.support && indicators.supportResistance.resistance) {
    const { support, resistance } = indicators.supportResistance;
    const nearestSupport = findNearestLevel(lastPrice, support);
    const nearestResistance = findNearestLevel(lastPrice, resistance);
    if (nearestSupport && nearestResistance) {
      insights.push(`Nearest support level: $${formatNumber(nearestSupport, szDecimals, isSpot)}. Nearest resistance level: $${formatNumber(nearestResistance, szDecimals, isSpot)}.`);
    }
  }

  // Order book analysis
  if (orderBook && orderBook.bids && orderBook.asks) {
    const bidAskRatio = calculateBidAskRatio(orderBook);
    insights.push(`The current bid/ask ratio is ${formatNumber(bidAskRatio, 2, true)}, indicating ${bidAskRatio > 1 ? 'stronger buying' : 'stronger selling'} pressure.`);
  }

  // Recent trades analysis
  if (recentTrades && recentTrades.length > 0) {
    const recentTradesTrend = analyzeRecentTrades(recentTrades);
    insights.push(`Recent trades show a ${recentTradesTrend.toLowerCase()} trend.`);
  }

  // Volume analysis
  if (candleData.length > 10) {
    const volumeTrend = analyzeVolumeTrend(candleData);
    insights.push(`Trading volume is ${volumeTrend.toLowerCase()} compared to the recent average.`);
  }

  return insights.join(' ');
};

export const calculateChannelPattern = (data) => {
  // Check if data is undefined or empty
  if (!data || data.length === 0) {
    console.warn('Insufficient data for channel pattern calculation');
    return { upperChannel: [], lowerChannel: [], channelWidth: 0 };
  }

  const period = Math.min(50, data.length);
  const recentData = data.slice(-period);

  // Linear regression calculation
  const linearRegression = (values) => {
    if (!values || values.length === 0) {
      return { slope: 0, intercept: 0 };
    }

    const n = values.length;
    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumXX = 0;

    for (let i = 0; i < n; i++) {
      sumX += i;
      sumY += values[i];
      sumXY += i * values[i];
      sumXX += i * i;
    }

    const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    return { slope, intercept };
  };

  const highs = recentData.map(d => d.high);
  const lows = recentData.map(d => d.low);

  const upperRegression = linearRegression(highs);
  const lowerRegression = linearRegression(lows);

  const upperChannel = recentData.map((candle, index) => ({
    time: candle.time,
    value: upperRegression.slope * index + upperRegression.intercept,
  }));

  const lowerChannel = recentData.map((candle, index) => ({
    time: candle.time,
    value: lowerRegression.slope * index + lowerRegression.intercept,
  }));

  // Calculate the channel width
  const channelWidth = upperChannel[0].value - lowerChannel[0].value;

  // Extend the channel lines
  const extendedUpperChannel = [...upperChannel];
  const extendedLowerChannel = [...lowerChannel];

  for (let i = 1; i <= 10; i++) {
    const nextTime = recentData[recentData.length - 1].time + i * (recentData[1].time - recentData[0].time);
    extendedUpperChannel.push({
      time: nextTime,
      value: upperRegression.slope * (period + i - 1) + upperRegression.intercept,
    });
    extendedLowerChannel.push({
      time: nextTime,
      value: lowerRegression.slope * (period + i - 1) + lowerRegression.intercept,
    });
  }

  return { upperChannel: extendedUpperChannel, lowerChannel: extendedLowerChannel, channelWidth };
};

export const analyzeLiquiditySwings = (data, volumeData) => {
  const swings = [];
  const threshold = 1.5; // Adjust this value to change sensitivity

  for (let i = 1; i < data.length - 1; i++) {
    const prevVolume = volumeData[i - 1];
    const currentVolume = volumeData[i];
    const nextVolume = volumeData[i + 1];

    if (currentVolume > prevVolume * threshold && currentVolume > nextVolume * threshold) {
      swings.push({
        time: data[i].time,
        price: data[i].close,
        volume: currentVolume,
        type: 'high'
      });
    } else if (currentVolume < prevVolume / threshold && currentVolume < nextVolume / threshold) {
      swings.push({
        time: data[i].time,
        price: data[i].close,
        volume: currentVolume,
        type: 'low'
      });
    }
  }

  return swings;
};

export const detectDivergence = (data, indicators) => {
  const divergences = [];
  const lookbackPeriod = 14;

  if (!indicators || !indicators.rsi || !indicators.macd || !indicators.macd.macdLine) {
    console.error('Invalid or missing indicator data for divergence detection');
    return divergences;
  }

  for (let i = lookbackPeriod; i < data.length; i++) {
    if (i >= indicators.rsi.length || i >= indicators.macd.macdLine.length) {
      break; // Stop if we've reached the end of the indicator data
    }

    const priceChange = data[i].close - data[i - lookbackPeriod].close;
    
    const currentRSI = indicators.rsi[i];
    const pastRSI = indicators.rsi[i - lookbackPeriod];
    const currentMACD = indicators.macd.macdLine[i];
    const pastMACD = indicators.macd.macdLine[i - lookbackPeriod];

    if (!currentRSI || !pastRSI || !currentMACD || !pastMACD) {
      continue; // Skip this iteration if any required data is missing
    }

    const rsiChange = currentRSI.value - pastRSI.value;
    const macdChange = currentMACD.value - pastMACD.value;

    if (priceChange > 0 && rsiChange < 0) {
      divergences.push({
        time: data[i].time,
        type: 'bearish',
        indicator: 'RSI'
      });
    } else if (priceChange < 0 && rsiChange > 0) {
      divergences.push({
        time: data[i].time,
        type: 'bullish',
        indicator: 'RSI'
      });
    }

    if (priceChange > 0 && macdChange < 0) {
      divergences.push({
        time: data[i].time,
        type: 'bearish',
        indicator: 'MACD'
      });
    } else if (priceChange < 0 && macdChange > 0) {
      divergences.push({
        time: data[i].time,
        type: 'bullish',
        indicator: 'MACD'
      });
    }
  }

  return divergences;
};

export const calculateLiquiditySwings = (data, options) => {
  const {
    length = 14,
    area = 'Wick Extremity',
    filterOptions = 'Count',
    filterValue = 0,
    intrabarTf,
    swingStrength = 'Normal', // 'Weak', 'Normal', 'Strong'
  } = options;

  const highSwings = [];
  const lowSwings = [];
  let tdCount = 0;
  let tdDirection = null;

  const calculateATR = (index, period) => {
    let sum = 0;
    for (let i = index; i > index - period && i >= 0; i--) {
      const high = data[i].high;
      const low = data[i].low;
      const prevClose = i > 0 ? data[i - 1].close : data[i].open;
      const tr = Math.max(high - low, Math.abs(high - prevClose), Math.abs(low - prevClose));
      sum += tr;
    }
    return sum / period;
  };

  const isPivotHigh = (i, strength) => {
    for (let j = 1; j <= strength; j++) {
      if (i - j < 0 || i + j >= data.length) return false;
      if (data[i].high <= data[i - j].high || data[i].high <= data[i + j].high) return false;
    }
    return true;
  };

  const isPivotLow = (i, strength) => {
    for (let j = 1; j <= strength; j++) {
      if (i - j < 0 || i + j >= data.length) return false;
      if (data[i].low >= data[i - j].low || data[i].low >= data[i + j].low) return false;
    }
    return true;
  };

  const getSwingStrength = (strength) => {
    switch (strength) {
      case 'Weak': return 1;
      case 'Strong': return 3;
      default: return 2;
    }
  };

  const strength = getSwingStrength(swingStrength);

  for (let i = length; i < data.length - length; i++) {
    // TD Sequential count
    if (data[i].close > data[i - 4].close) {
      if (tdDirection === 'down' || tdDirection === null) {
        tdCount = 1;
        tdDirection = 'up';
      } else {
        tdCount++;
      }
    } else if (data[i].close < data[i - 4].close) {
      if (tdDirection === 'up' || tdDirection === null) {
        tdCount = 1;
        tdDirection = 'down';
      } else {
        tdCount++;
      }
    } else {
      tdCount = 0;
      tdDirection = null;
    }

    const atr = calculateATR(i, 14);

    if (isPivotHigh(i, strength)) {
      const swingHigh = {
        time: data[i].time,
        value: data[i].high,
        bottom: area === 'Wick Extremity' ? Math.max(data[i].open, data[i].close) : data[i].low,
        high: data[i].high,
        low: data[i].low,
      };
      
      const count = data.slice(i + 1).filter(d => d.low < swingHigh.value && d.high > swingHigh.bottom).length;
      const volume = data.slice(i + 1).reduce((sum, d) => {
        return d.low < swingHigh.value && d.high > swingHigh.bottom ? sum + d.volume : sum;
      }, 0);

      if ((filterOptions === 'Count' && count > filterValue) || 
          (filterOptions === 'Volume' && volume > filterValue)) {
        highSwings.push({
          ...swingHigh,
          count,
          volume,
          index: i,
          tdCount,
          tdDirection,
          atr,
          strength: swingStrength,
        });
      }
    }

    if (isPivotLow(i, strength)) {
      const swingLow = {
        time: data[i].time,
        value: data[i].low,
        top: area === 'Wick Extremity' ? Math.min(data[i].open, data[i].close) : data[i].high,
        high: data[i].high,
        low: data[i].low,
      };
      
      const count = data.slice(i + 1).filter(d => d.high > swingLow.value && d.low < swingLow.top).length;
      const volume = data.slice(i + 1).reduce((sum, d) => {
        return d.high > swingLow.value && d.low < swingLow.top ? sum + d.volume : sum;
      }, 0);

      if ((filterOptions === 'Count' && count > filterValue) || 
          (filterOptions === 'Volume' && volume > filterValue)) {
        lowSwings.push({
          ...swingLow,
          count,
          volume,
          index: i,
          tdCount,
          tdDirection,
          atr,
          strength: swingStrength,
        });
      }
    }
  }

  return { highSwings, lowSwings };
};

const calculateBidAskRatio = (orderBook) => {
  const totalBidVolume = orderBook.bids.reduce((sum, bid) => sum + parseFloat(bid.sz), 0);
  const totalAskVolume = orderBook.asks.reduce((sum, ask) => sum + parseFloat(ask.sz), 0);
  return totalBidVolume / totalAskVolume;
};

const analyzeRecentTrades = (recentTrades) => {
  const buyVolume = recentTrades.filter(trade => trade.side === 'B').reduce((sum, trade) => sum + parseFloat(trade.sz), 0);
  const sellVolume = recentTrades.filter(trade => trade.side === 'S').reduce((sum, trade) => sum + parseFloat(trade.sz), 0);
  if (buyVolume > sellVolume * 1.2) return 'Bullish';
  if (sellVolume > buyVolume * 1.2) return 'Bearish';
  return 'Neutral';
};

const analyzeVolumeTrend = (candleData) => {
  const recentVolumes = candleData.slice(-10).map(d => d.volume);
  const avgVolume = recentVolumes.reduce((a, b) => a + b, 0) / recentVolumes.length;
  const latestVolume = recentVolumes[recentVolumes.length - 1];

  if (latestVolume > avgVolume * 1.5) return 'Significantly higher';
  if (latestVolume > avgVolume * 1.2) return 'Higher';
  if (latestVolume < avgVolume * 0.8) return 'Lower';
  if (latestVolume < avgVolume * 0.5) return 'Significantly lower';
  return 'Average';
};

const findNearestFibonacciLevel = (price, fibLevels) => {
  return fibLevels.reduce((nearest, current) => 
    Math.abs(current.value - price) < Math.abs(nearest.value - price) ? current : nearest
  );
};

const findNearestLevel = (price, levels) => {
  if (!levels || levels.length === 0) {
    console.warn('No levels provided to findNearestLevel');
    return null;
  }
  return levels.reduce((nearest, current) => 
    Math.abs(current - price) < Math.abs(nearest - price) ? current : nearest
  );
};

const getDMISignal = (dmi) => {
  const latestDMI = {
    diPlus: dmi.diPlus[dmi.diPlus.length - 1].value,
    diMinus: dmi.diMinus[dmi.diMinus.length - 1].value,
    adx: dmi.adx[dmi.adx.length - 1].value,
  };

  if (latestDMI.diPlus > latestDMI.diMinus && latestDMI.adx > 25) return 'Bullish';
  if (latestDMI.diMinus > latestDMI.diPlus && latestDMI.adx > 25) return 'Bearish';
  return 'Neutral';
};

const getTDSequentialSignal = (tdSequential) => {
  if (tdSequential.value === 9) {
    return tdSequential.setup === 'bullish' ? 'Potential bullish reversal' : 'Potential bearish reversal';
  }
  return 'No clear signal';
};

const getVWAPSignal = (price, vwap) => {
  if (price > vwap * 1.05) return 'Significantly above VWAP';
  if (price > vwap) return 'Above VWAP';
  if (price < vwap * 0.95) return 'Significantly below VWAP';
  if (price < vwap) return 'Below VWAP';
  return 'Near VWAP';
};

const getBollingerBandsSignal = (price, bands) => {
  if (price > bands.upper) return 'above';
  if (price < bands.lower) return 'below';
  return 'within';
};

const getLookbackPeriod = (timeframe) => {
  switch (timeframe) {
    case '1m': return 60;
    case '3m': return 48;
    case '5m': return 48;
    case '15m': return 32;
    case '30m': return 24;
    case '1h': return 24;
    case '2h': return 24;
    case '4h': return 14;
    case '8h': return 24;
    case '12h': return 24;
    case '1d': return 14;
    case '3d': return 14;
    case '1w': return 14;
    case '1M': return 12;
    default: return 14;
  }
};

export const calculateConsensusSignals = (data, indicators, volume, timeframe) => {
  const signals = [];
  const lookbackPeriod = getLookbackPeriod(timeframe);

  // Ensure we have enough data and volume
  if (!data || data.length < lookbackPeriod || !Array.isArray(volume)) {
    console.warn(`Insufficient data or invalid volume for timeframe ${timeframe}. Need at least ${lookbackPeriod} candles and a valid volume array.`);
    return signals;
  }

  try {
    for (let i = lookbackPeriod; i < data.length; i++) {
      const slice = data.slice(i - lookbackPeriod, i + 1);
      const currentCandle = slice[slice.length - 1];
      
      // Check if all required indicator data is available
      if (!indicators || !indicators.rsi || !indicators.macd || !indicators.bollingerBands) {
        console.warn('Missing required indicator data');
        continue;
      }

      const validData = isValidData(indicators, i);

      if (!validData) {
        console.warn(`Invalid indicator data at index ${i} for timeframe ${timeframe}`);
        continue;
      }

      // Use a default volume value if volume data is not available
      const volumeSlice = Array.isArray(volume) ? volume.slice(i - lookbackPeriod, i + 1) : new Array(lookbackPeriod + 1).fill(0);

      const signal = analyzeConsensus(slice, indicators, volumeSlice, i, timeframe, validData);
      if (signal) {
        signals.push({
          time: currentCandle.time,
          type: signal,
        });
      }
    }

    return signals;
  } catch (error) {
    console.error("Error in calculateConsensusSignals:", error);
    throw new Error("Failed to calculate Consensus signals: " + error.message);
  }
};

const isValidData = (indicators, index) => {
  return {
    rsi: indicators.rsi && indicators.rsi[index],
    macd: indicators.macd && indicators.macd.macdLine && indicators.macd.macdLine[index] &&
          indicators.macd.signalLine && indicators.macd.signalLine[index] &&
          indicators.macd.histogram && indicators.macd.histogram[index],
    bollingerBands: indicators.bollingerBands && indicators.bollingerBands[index],
    dmi: indicators.dmi && indicators.dmi.adx && indicators.dmi.adx[index] &&
         indicators.dmi.diPlus && indicators.dmi.diPlus[index] &&
         indicators.dmi.diMinus && indicators.dmi.diMinus[index],
    vwap: indicators.vwap && indicators.vwap[index],
    ma: indicators.ma && indicators.ma.ma5 && indicators.ma.ma5[index] &&
        indicators.ma.ma10 && indicators.ma.ma10[index] &&
        indicators.ma.ma20 && indicators.ma.ma20[index] &&
        indicators.ma.ma50 && indicators.ma.ma50[index] &&
        indicators.ma.ma100 && indicators.ma.ma100[index] &&
        indicators.ma.ma200 && indicators.ma.ma200[index],
    tdSequential: indicators.tdSequential && indicators.tdSequential[index],
    channelPattern: indicators.channelPattern,
    liquiditySwings: indicators.liquiditySwings && indicators.liquiditySwings[index],
    divergence: indicators.divergence && indicators.divergence[index],
    fibonacci: indicators.fibonacci,
    stochRSI: indicators.stochRSI && indicators.stochRSI.k && indicators.stochRSI.k[index] &&
              indicators.stochRSI.d && indicators.stochRSI.d[index]
  };
};

const analyzeConsensus = (slice, indicators, volumeSlice, index, timeframe, validData) => {
  const currentCandle = slice[slice.length - 1];
  const prevCandle = slice[slice.length - 2];

  // Extract relevant indicator values
  const rsi = validData.rsi ? indicators.rsi[index].value : null;
  const macd = validData.macd ? indicators.macd.macdLine[index].value : null;
  const signal = validData.macd ? indicators.macd.signalLine[index].value : null;
  const histogram = validData.macd ? indicators.macd.histogram[index].value : null;
  const bb = validData.bollingerBands ? indicators.bollingerBands[index] : null;
  const adx = validData.dmi ? indicators.dmi.adx[index].value : null;
  const diPlus = validData.dmi ? indicators.dmi.diPlus[index].value : null;
  const diMinus = validData.dmi ? indicators.dmi.diMinus[index].value : null;
  const vwap = validData.vwap ? indicators.vwap[index].value : null;
  const ma5 = validData.ma ? indicators.ma.ma5[index].value : null;
  const ma10 = validData.ma ? indicators.ma.ma10[index].value : null;
  const ma20 = validData.ma ? indicators.ma.ma20[index].value : null;
  const ma50 = validData.ma ? indicators.ma.ma50[index].value : null;
  const ma100 = validData.ma ? indicators.ma.ma100[index].value : null;
  const ma200 = validData.ma ? indicators.ma.ma200[index].value : null;
  const tdSequential = validData.tdSequential ? indicators.tdSequential[index] : null;
  const channelPattern = validData.channelPattern ? indicators.channelPattern : null;
  const liquiditySwing = validData.liquiditySwings ? indicators.liquiditySwings[index] : null;
  const divergence = validData.divergence ? indicators.divergence[index] : null;
  const fibonacci = validData.fibonacci ? indicators.fibonacci : null;
  const stochK = validData.stochRSI ? indicators.stochRSI.k[index].value : null;
  const stochD = validData.stochRSI ? indicators.stochRSI.d[index].value : null;

  // Calculate additional metrics
  const volumeChange = (volumeSlice[volumeSlice.length - 1] - volumeSlice[volumeSlice.length - 2]) / volumeSlice[volumeSlice.length - 2];
  const priceChange = (currentCandle.close - prevCandle.close) / prevCandle.close;
  const volatility = calculateVolatility(slice);
  const trendStrength = calculateTrendStrength(slice, ma50, ma200);
  const momentumScore = calculateMomentumScore(slice, rsi, macd, signal);
  const supportResistance = calculateSupportResistanceScore(currentCandle.close, indicators.supportResistance);

  // Define and weight the conditions
  const conditions = [
    { condition: rsi !== null && rsi < 30, weight: 1.5, type: 'BUY' },
    { condition: rsi !== null && rsi > 70, weight: 1.5, type: 'SELL' },
    { condition: macd !== null && signal !== null && macd > signal, weight: 1.2, type: 'BUY' },
    { condition: macd !== null && signal !== null && macd < signal, weight: 1.2, type: 'SELL' },
    { condition: bb !== null && currentCandle.close < bb.lower, weight: 1, type: 'BUY' },
    { condition: bb !== null && currentCandle.close > bb.upper, weight: 1, type: 'SELL' },
    { condition: adx !== null && diPlus !== null && diMinus !== null && adx > 25 && diPlus > diMinus, weight: 1.3, type: 'BUY' },
    { condition: adx !== null && diPlus !== null && diMinus !== null && adx > 25 && diMinus > diPlus, weight: 1.3, type: 'SELL' },
    { condition: vwap !== null && currentCandle.close < vwap, weight: 0.8, type: 'BUY' },
    { condition: vwap !== null && currentCandle.close > vwap, weight: 0.8, type: 'SELL' },
    { condition: tdSequential !== null && tdSequential.value >= 8 && tdSequential.setup === 'bullish', weight: 1.4, type: 'BUY' },
    { condition: tdSequential !== null && tdSequential.value >= 8 && tdSequential.setup === 'bearish', weight: 1.4, type: 'SELL' },
    { condition: volumeChange > 0.2, weight: 1.1, type: 'BUY' },
    { condition: volumeChange > 0.2, weight: 1.1, type: 'SELL' },
    { condition: trendStrength !== null && trendStrength < -0.2, weight: 1.2, type: 'BUY' },
    { condition: trendStrength !== null && trendStrength > 0.2, weight: 1.2, type: 'SELL' },
    { condition: momentumScore !== null && momentumScore > 0.5, weight: 1.3, type: 'BUY' },
    { condition: momentumScore !== null && momentumScore < -0.5, weight: 1.3, type: 'SELL' },
    { condition: supportResistance > 0.6, weight: 1.1, type: 'BUY' },
    { condition: supportResistance < -0.6, weight: 1.1, type: 'SELL' },
    // New conditions for additional MAs
    { condition: ma5 !== null && ma10 !== null && ma5 > ma10, weight: 1, type: 'BUY' },
    { condition: ma5 !== null && ma10 !== null && ma5 < ma10, weight: 1, type: 'SELL' },
    { condition: ma20 !== null && ma50 !== null && ma20 > ma50, weight: 1.1, type: 'BUY' },
    { condition: ma20 !== null && ma50 !== null && ma20 < ma50, weight: 1.1, type: 'SELL' },
    { condition: ma50 !== null && ma100 !== null && ma50 > ma100, weight: 1.2, type: 'BUY' },
    { condition: ma50 !== null && ma100 !== null && ma50 < ma100, weight: 1.2, type: 'SELL' },
    // Channel Pattern condition
    { condition: channelPattern && currentCandle.close < channelPattern.lowerChannel[channelPattern.lowerChannel.length - 1].value, weight: 1.2, type: 'BUY' },
    { condition: channelPattern && currentCandle.close > channelPattern.upperChannel[channelPattern.upperChannel.length - 1].value, weight: 1.2, type: 'SELL' },
    // Liquidity Swings condition
    { condition: liquiditySwing && liquiditySwing.type === 'high', weight: 1.1, type: 'SELL' },
    { condition: liquiditySwing && liquiditySwing.type === 'low', weight: 1.1, type: 'BUY' },
    // Divergence condition
    { condition: divergence && divergence.type === 'bullish', weight: 1.3, type: 'BUY' },
    { condition: divergence && divergence.type === 'bearish', weight: 1.3, type: 'SELL' },
    // Fibonacci levels condition
    { condition: fibonacci && currentCandle.close < fibonacci[1].value, weight: 1.1, type: 'BUY' },
    { condition: fibonacci && currentCandle.close > fibonacci[4].value, weight: 1.1, type: 'SELL' },
    // Stochastic RSI conditions
    { condition: stochK !== null && stochD !== null && stochK < 20 && stochD < 20, weight: 1.2, type: 'BUY' },
    { condition: stochK !== null && stochD !== null && stochK > 80 && stochD > 80, weight: 1.2, type: 'SELL' },
    { condition: stochK !== null && stochD !== null && stochK > stochD && stochK < 20, weight: 1.1, type: 'BUY' },
    { condition: stochK !== null && stochD !== null && stochK < stochD && stochK > 80, weight: 1.1, type: 'SELL' },
  ];

  // Calculate the weighted scores for BUY and SELL signals
  let buyScore = 0;
  let sellScore = 0;
  let totalBuyWeight = 0;
  let totalSellWeight = 0;

  conditions.forEach(({ condition, weight, type }) => {
    if (condition) {
      if (type === 'BUY') {
        buyScore += weight;
        totalBuyWeight += weight;
      } else {
        sellScore += weight;
        totalSellWeight += weight;
      }
    }
  });

  const buySignalStrength = totalBuyWeight > 0 ? buyScore / totalBuyWeight : 0;
  const sellSignalStrength = totalSellWeight > 0 ? sellScore / totalSellWeight : 0;

  // Define thresholds for signal generation
  const strongSignalThreshold = 0.7;
  const moderateSignalThreshold = 0.5;

  // Generate signals based on the calculated strengths
  if (buySignalStrength > strongSignalThreshold && buySignalStrength > sellSignalStrength) {
    return 'STRONG BUY';
  } else if (sellSignalStrength > strongSignalThreshold && sellSignalStrength > buySignalStrength) {
    return 'STRONG SELL';
  } else if (buySignalStrength > moderateSignalThreshold && buySignalStrength > sellSignalStrength) {
    return 'BUY';
  } else if (sellSignalStrength > moderateSignalThreshold && sellSignalStrength > buySignalStrength) {
    return 'SELL';
  }

  return null;
};

const calculateVolatility = (slice) => {
  const returns = slice.slice(1).map((candle, i) => 
    Math.log(candle.close / slice[i].close)
  );
  return Math.sqrt(returns.reduce((sum, ret) => sum + ret * ret, 0) / returns.length) * Math.sqrt(252);
};

const calculateTrendStrength = (slice, ma50, ma200) => {
  const shortTermTrend = (slice[slice.length - 1].close - ma50) / ma50;
  const longTermTrend = (slice[slice.length - 1].close - ma200) / ma200;
  return (shortTermTrend + longTermTrend) / 2;
};

const calculateMomentumScore = (slice, rsi, macd, signal) => {
  const priceChange = (slice[slice.length - 1].close - slice[0].close) / slice[0].close;
  const rsiScore = (rsi - 50) / 50;
  const macdScore = (macd - signal) / Math.abs(signal);
  return (priceChange + rsiScore + macdScore) / 3;
};

const calculateSupportResistanceScore = (price, supportResistance) => {
  const { support, resistance } = supportResistance;
  const nearestSupport = support.reduce((prev, curr) => 
    Math.abs(curr - price) < Math.abs(prev - price) ? curr : prev
  );
  const nearestResistance = resistance.reduce((prev, curr) => 
    Math.abs(curr - price) < Math.abs(prev - price) ? curr : prev
  );
  const supportDistance = (price - nearestSupport) / price;
  const resistanceDistance = (nearestResistance - price) / price;
  return (resistanceDistance - supportDistance) / (resistanceDistance + supportDistance);
};

export const detectHeadAndShoulders = (data) => {
  const patterns = [];
  const breakouts = [];
  const window = 20; // Adjust this value to change the detection window

  for (let i = window; i < data.length - window; i++) {
    const leftShoulder = findExtremum(data, i - window, i, 'high');
    const head = findExtremum(data, i, i + window, 'high');
    const rightShoulder = findExtremum(data, i + window, data.length, 'high');

    if (leftShoulder && head && rightShoulder) {
      if (isValidHeadAndShoulders(leftShoulder, head, rightShoulder)) {
        const pattern = {
          type: 'head_and_shoulders',
          left_shoulder: leftShoulder,
          head: head,
          right_shoulder: rightShoulder,
        };
        patterns.push(pattern);

        // Check for breakouts
        const necklinePrice = (leftShoulder.price + rightShoulder.price) / 2;
        const breakout = findBreakout(data, rightShoulder.index, necklinePrice, head.price);
        if (breakout) {
          breakouts.push(breakout);
        }
      }
    }

    // Check for inverse head and shoulders
    const leftShoulderInv = findExtremum(data, i - window, i, 'low');
    const headInv = findExtremum(data, i, i + window, 'low');
    const rightShoulderInv = findExtremum(data, i + window, data.length, 'low');

    if (leftShoulderInv && headInv && rightShoulderInv) {
      if (isValidInverseHeadAndShoulders(leftShoulderInv, headInv, rightShoulderInv)) {
        const pattern = {
          type: 'inverse_head_and_shoulders',
          left_shoulder: leftShoulderInv,
          head: headInv,
          right_shoulder: rightShoulderInv,
        };
        patterns.push(pattern);

        // Check for breakouts
        const necklinePrice = (leftShoulderInv.price + rightShoulderInv.price) / 2;
        const breakout = findBreakout(data, rightShoulderInv.index, necklinePrice, headInv.price);
        if (breakout) {
          breakouts.push(breakout);
        }
      }
    }
  }

  // Remove duplicate patterns and breakouts
  const uniquePatterns = removeDuplicatePatterns(patterns);
  const uniqueBreakouts = removeDuplicateBreakouts(breakouts);

  // Sort breakouts chronologically
  breakouts.sort((a, b) => a.time - b.time);

  return { patterns: uniquePatterns, breakouts: uniqueBreakouts };
};

const findBreakout = (data, startIndex, necklinePrice, headPrice) => {
  for (let j = startIndex + 1; j < data.length; j++) {
    if (data[j].close < necklinePrice) {
      return { type: 'breakdown', time: data[j].time };
    } else if (data[j].close > headPrice) {
      return { type: 'breakup', time: data[j].time };
    }
  }
  return null;
};

const removeDuplicatePatterns = (patterns) => {
  return patterns.filter((pattern, index, self) =>
    index === self.findIndex((t) => (
      t.left_shoulder.time === pattern.left_shoulder.time &&
      t.head.time === pattern.head.time &&
      t.right_shoulder.time === pattern.right_shoulder.time
    ))
  );
};

const removeDuplicateBreakouts = (breakouts) => {
  return breakouts.filter((breakout, index, self) =>
    index === self.findIndex((t) => (
      t.type === breakout.type &&
      t.time === breakout.time
    ))
  );
};

const findExtremum = (data, start, end, type) => {
  let extremum = type === 'high' ? -Infinity : Infinity;
  let extremumIndex = -1;

  for (let i = start; i < end; i++) {
    if ((type === 'high' && data[i].high > extremum) || (type === 'low' && data[i].low < extremum)) {
      extremum = type === 'high' ? data[i].high : data[i].low;
      extremumIndex = i;
    }
  }

  return extremumIndex !== -1 ? { price: extremum, time: data[extremumIndex].time, index: extremumIndex } : null;
};

const isValidHeadAndShoulders = (leftShoulder, head, rightShoulder) => {
  const headHeight = head.price - Math.min(leftShoulder.price, rightShoulder.price);
  const shoulderDifference = Math.abs(leftShoulder.price - rightShoulder.price);

  return (
    head.price > leftShoulder.price &&
    head.price > rightShoulder.price &&
    shoulderDifference / headHeight < 0.1
  );
};

const isValidInverseHeadAndShoulders = (leftShoulder, head, rightShoulder) => {
  const headDepth = Math.max(leftShoulder.price, rightShoulder.price) - head.price;
  const shoulderDifference = Math.abs(leftShoulder.price - rightShoulder.price);

  return (
    head.price < leftShoulder.price &&
    head.price < rightShoulder.price &&
    shoulderDifference / headDepth < 0.1
  );
};

export const predictTrend = (pattern, data, breakouts) => {
  const necklinePrice = (pattern.left_shoulder.price + pattern.right_shoulder.price) / 2;
  const patternHeight = Math.abs(pattern.head.price - necklinePrice);
  const currentPrice = data[data.length - 1].close;
  
  // Use the most recent breakout to determine direction
  const latestBreakout = breakouts[breakouts.length - 1];
  const direction = latestBreakout.type === 'breakdown' ? 'Bearish' : 'Bullish';

  // Calculate target price based on the direction
  let targetPrice;
  if (direction === 'Bullish') {
    targetPrice = Math.max(currentPrice, necklinePrice) + patternHeight;
  } else {
    targetPrice = Math.min(currentPrice, necklinePrice) - patternHeight;
  }

  // Calculate confidence based on pattern completion and current price relative to neckline
  let confidence = 0.5; // Base confidence

  // Adjust confidence based on pattern completion
  const patternCompletion = (data[data.length - 1].time - pattern.right_shoulder.time) / 
    (pattern.right_shoulder.time - pattern.left_shoulder.time);
  confidence += Math.min(patternCompletion, 1) * 0.3;

  // Adjust confidence based on current price relative to neckline
  const priceToNeckline = Math.abs(currentPrice - necklinePrice) / patternHeight;
  confidence += Math.min(priceToNeckline, 1) * 0.2;

  // Ensure confidence is between 0 and 1
  confidence = Math.min(Math.max(confidence, 0), 1);

  return {
    direction,
    confidence,
    targetPrice,
  };
};

export const calculateStochastic = (data, period = 14, smoothK = 3, smoothD = 3) => {
  const stochastic = [];
  for (let i = period - 1; i < data.length; i++) {
    const slice = data.slice(i - period + 1, i + 1);
    const low = Math.min(...slice.map(d => d.low));
    const high = Math.max(...slice.map(d => d.high));
    const close = slice[slice.length - 1].close;
    const k = ((close - low) / (high - low)) * 100;
    stochastic.push({ time: data[i].time, k });
  }

  const smoothedK = movingAverage(stochastic.map(s => s.k), smoothK);
  const smoothedD = movingAverage(smoothedK, smoothD);

  return stochastic.map((s, i) => ({
    time: s.time,
    k: smoothedK[i],
    d: smoothedD[i]
  }));
};

export const calculateOBV = (data) => {
  let obv = 0;
  return data.map((candle, i) => {
    if (i === 0) {
      return { time: candle.time, value: 0 };
    }
    if (candle.close > data[i - 1].close) {
      obv += candle.volume;
    } else if (candle.close < data[i - 1].close) {
      obv -= candle.volume;
    }
    return { time: candle.time, value: obv };
  });
};

export const calculateIchimokuCloud = (data, conversionPeriod = 9, basePeriod = 26, leadingSpanBPeriod = 52, laggingSpanPeriod = 26) => {
  const ichimoku = [];
  for (let i = Math.max(conversionPeriod, basePeriod, leadingSpanBPeriod) - 1; i < data.length; i++) {
    const conversionLine = (Math.max(...data.slice(i - conversionPeriod + 1, i + 1).map(d => d.high)) + 
                            Math.min(...data.slice(i - conversionPeriod + 1, i + 1).map(d => d.low))) / 2;
    const baseLine = (Math.max(...data.slice(i - basePeriod + 1, i + 1).map(d => d.high)) + 
                      Math.min(...data.slice(i - basePeriod + 1, i + 1).map(d => d.low))) / 2;
    const leadingSpanA = (conversionLine + baseLine) / 2;
    const leadingSpanB = (Math.max(...data.slice(i - leadingSpanBPeriod + 1, i + 1).map(d => d.high)) + 
                          Math.min(...data.slice(i - leadingSpanBPeriod + 1, i + 1).map(d => d.low))) / 2;
    
    ichimoku.push({
      time: data[i].time,
      conversionLine,
      baseLine,
      leadingSpanA,
      leadingSpanB,
      laggingSpan: i >= laggingSpanPeriod ? data[i - laggingSpanPeriod].close : null
    });
  }
  return ichimoku;
};

// Helper function for moving averages
const movingAverage = (data, period) => {
  const result = [];
  for (let i = period - 1; i < data.length; i++) {
    const sum = data.slice(i - period + 1, i + 1).reduce((a, b) => a + b, 0);
    result.push(sum / period);
  }
  return result;
};

const PATTERN_RATIOS = {
  GARTLEY: { XAB: 0.618, ABC: 0.382, BCD: 1.272, XAD: 0.786 },
  BUTTERFLY: { XAB: 0.786, ABC: 0.382, BCD: 1.618, XAD: 1.27 },
  BAT: { XAB: 0.382, ABC: 0.382, BCD: 1.618, XAD: 0.886 },
  CRAB: { XAB: 0.382, ABC: 0.618, BCD: 2.618, XAD: 1.618 },
};

export const detectHarmonicPatterns = (data, sensitivity = 0.13) => {
  const patterns = [];
  const isFibRatio = (ratio, target) => Math.abs(ratio - target) <= sensitivity;

  const roundToDecimals = (number, decimals = 3) => {
    return Number(number.toFixed(decimals));
  };

  for (let i = 0; i < data.length - 4; i++) {
    const X = { time: data[i].time, price: data[i].high };
    let A, B, C, D;

    for (let j = i + 1; j < data.length - 3; j++) {
      if (data[j].low < X.price && data[j].time > X.time) {
        A = { time: data[j].time, price: data[j].low };

        for (let k = j + 1; k < data.length - 2; k++) {
          if (data[k].high > A.price && data[k].time > A.time) {
            B = { time: data[k].time, price: data[k].high };
            const XAB = roundToDecimals((B.price - A.price) / (X.price - A.price));

            for (let l = k + 1; l < data.length - 1; l++) {
              if (data[l].low < B.price && data[l].time > B.time) {
                C = { time: data[l].time, price: data[l].low };
                const ABC = roundToDecimals((C.price - B.price) / (A.price - B.price));

                for (let m = l + 1; m < data.length; m++) {
                  if (data[m].high > C.price && data[m].time > C.time) {
                    D = { time: data[m].time, price: data[m].high };
                    const BCD = roundToDecimals((D.price - C.price) / (B.price - C.price));
                    const XAD = roundToDecimals((D.price - A.price) / (X.price - A.price));

                    const patternBase = { X, A, B, C, D, XAB, ABC, BCD, XAD };

                    // Check for Gartley pattern
                    if (isFibRatio(XAB, 0.618) && isFibRatio(ABC, 0.382) && isFibRatio(BCD, 1.272) && isFibRatio(XAD, 0.786)) {
                      patterns.push({ ...patternBase, type: 'GARTLEY' });
                    }
                    // Check for Butterfly pattern
                    else if (isFibRatio(XAB, 0.786) && isFibRatio(ABC, 0.382) && isFibRatio(BCD, 1.618) && isFibRatio(XAD, 1.27)) {
                      patterns.push({ ...patternBase, type: 'BUTTERFLY' });
                    }
                    // Check for Bat pattern
                    else if (isFibRatio(XAB, 0.382) && isFibRatio(ABC, 0.382) && isFibRatio(BCD, 1.618) && isFibRatio(XAD, 0.886)) {
                      patterns.push({ ...patternBase, type: 'BAT' });
                    }
                    // Check for Crab pattern
                    else if (isFibRatio(XAB, 0.382) && isFibRatio(ABC, 0.618) && isFibRatio(BCD, 2.618) && isFibRatio(XAD, 1.618)) {
                      patterns.push({ ...patternBase, type: 'CRAB' });
                    }
                    break;
                  }
                }
                break;
              }
            }
            break;
          }
        }
        break;
      }
    }
  }

  return patterns;
};

export const combineAnalysis = (indicators, headAndShouldersPrediction, priceData) => {
  if (!indicators || !priceData || priceData.length === 0) {
    console.warn('Invalid input for combineAnalysis');
    return null;
  }

  const latestPrice = priceData[priceData.length - 1].close;
  const indicatorValues = getLatestIndicatorValues(indicators);

  let bullishPoints = 0;
  let bearishPoints = 0;
  let analysis = [];

  // Head and Shoulders analysis
  if (headAndShouldersPrediction && headAndShouldersPrediction.direction) {
    if (headAndShouldersPrediction.direction === 'Bullish') {
      bullishPoints += 2;
      analysis.push(`Head and Shoulders pattern suggests a bullish trend (Confidence: ${(headAndShouldersPrediction.confidence * 100).toFixed(2)}%)`);
    } else {
      bearishPoints += 2;
      analysis.push(`Head and Shoulders pattern suggests a bearish trend (Confidence: ${(headAndShouldersPrediction.confidence * 100).toFixed(2)}%)`);
    }
  }

  // RSI analysis
  if (indicatorValues.rsi !== null) {
    if (indicatorValues.rsi < 30) {
      bullishPoints++;
      analysis.push('RSI indicates oversold conditions, potential bullish reversal');
    } else if (indicatorValues.rsi > 70) {
      bearishPoints++;
      analysis.push('RSI indicates overbought conditions, potential bearish reversal');
    }
  }

  // MACD analysis
  if (indicatorValues.macd && indicatorValues.macd.macd !== null && indicatorValues.macd.signal !== null) {
    if (indicatorValues.macd.macd > indicatorValues.macd.signal) {
      bullishPoints++;
      analysis.push('MACD is above signal line, indicating bullish momentum');
    } else {
      bearishPoints++;
      analysis.push('MACD is below signal line, indicating bearish momentum');
    }
  }

  // Bollinger Bands analysis
  if (indicatorValues.bollingerBands && typeof indicatorValues.bollingerBands === 'object') {
    const { upper, lower } = indicatorValues.bollingerBands;
    if (typeof upper === 'number' && typeof lower === 'number') {
      if (latestPrice > upper) {
        bearishPoints++;
        analysis.push('Price is above upper Bollinger Band, potential overbought condition');
      } else if (latestPrice < lower) {
        bullishPoints++;
        analysis.push('Price is below lower Bollinger Band, potential oversold condition');
      }
    }
  }

  // Stochastic RSI analysis
  if (indicatorValues.stochRSI && typeof indicatorValues.stochRSI === 'object') {
    const { k, d } = indicatorValues.stochRSI;
    if (typeof k === 'number' && typeof d === 'number') {
      if (k < 20 && d < 20) {
        bullishPoints++;
        analysis.push('Stochastic RSI indicates oversold conditions, potential bullish reversal');
      } else if (k > 80 && d > 80) {
        bearishPoints++;
        analysis.push('Stochastic RSI indicates overbought conditions, potential bearish reversal');
      }
    }
  }

  // DMI analysis
  if (indicatorValues.dmi && typeof indicatorValues.dmi === 'object') {
    const { diPlus, diMinus, adx } = indicatorValues.dmi;
    if (typeof diPlus === 'number' && typeof diMinus === 'number' && typeof adx === 'number') {
      if (diPlus > diMinus && adx > 25) {
        bullishPoints++;
        analysis.push('DMI indicates strong bullish trend');
      } else if (diMinus > diPlus && adx > 25) {
        bearishPoints++;
        analysis.push('DMI indicates strong bearish trend');
      }
    }
  }

  // Fibonacci levels analysis
  if (Array.isArray(indicatorValues.fibonacci)) {
    const nearestFibLevel = indicatorValues.fibonacci.find(level => Math.abs(level.value - latestPrice) / latestPrice < 0.01);
    if (nearestFibLevel) {
      analysis.push(`Price is near Fibonacci level ${nearestFibLevel.level}`);
    }
  }

  // Channel Pattern analysis
  if (indicatorValues.channelPattern !== null) {
    const upperChannel = indicatorValues.channelPattern.upperChannel;
    const lowerChannel = indicatorValues.channelPattern.lowerChannel;
    if (upperChannel && lowerChannel && upperChannel.length > 0 && lowerChannel.length > 0) {
      if (latestPrice > upperChannel[upperChannel.length - 1].value) {
        bullishPoints++;
        analysis.push('Price is above the upper channel, indicating strong bullish momentum');
      } else if (latestPrice < lowerChannel[lowerChannel.length - 1].value) {
        bearishPoints++;
        analysis.push('Price is below the lower channel, indicating strong bearish momentum');
      }
    }
  }

  // Liquidity Swings analysis
  if (indicatorValues.liquiditySwings !== null && indicatorValues.liquiditySwings.type !== null) {
    if (indicatorValues.liquiditySwings.type === 'high') {
      bearishPoints++;
      analysis.push('Recent high liquidity swing, potential for bearish reversal');
    } else if (indicatorValues.liquiditySwings.type === 'low') {
      bullishPoints++;
      analysis.push('Recent low liquidity swing, potential for bullish reversal');
    }
  }

  // Chaikin Money Flow analysis
  if (typeof indicatorValues.cmf === 'number') {
    if (indicatorValues.cmf > 0.05) {
      bullishPoints++;
      analysis.push('Chaikin Money Flow is positive, indicating buying pressure');
    } else if (indicatorValues.cmf < -0.05) {
      bearishPoints++;
      analysis.push('Chaikin Money Flow is negative, indicating selling pressure');
    }
  }

  // Pivot Points analysis
  if (indicatorValues.pivotPoints && typeof indicatorValues.pivotPoints === 'object') {
    const pivotEntries = Object.entries(indicatorValues.pivotPoints);
    if (pivotEntries.length > 0) {
      const nearestPivot = pivotEntries.reduce((a, b) => Math.abs(b[1] - latestPrice) < Math.abs(a[1] - latestPrice) ? b : a);
      analysis.push(`Price is nearest to ${nearestPivot[0]} pivot point at ${nearestPivot[1]}`);
    }
  }

  // VWAP analysis
  if (typeof indicatorValues.vwap === 'number') {
    if (latestPrice > indicatorValues.vwap) {
      bullishPoints++;
      analysis.push('Price is above VWAP, indicating bullish sentiment');
    } else {
      bearishPoints++;
      analysis.push('Price is below VWAP, indicating bearish sentiment');
    }
  }

  // Keltner Channels analysis
  if (indicatorValues.keltnerChannels && typeof indicatorValues.keltnerChannels === 'object') {
    const { upper, lower } = indicatorValues.keltnerChannels;
    if (typeof upper === 'number' && typeof lower === 'number') {
      if (latestPrice > upper) {
        bearishPoints++;
        analysis.push('Price is above upper Keltner Channel, potential overbought condition');
      } else if (latestPrice < lower) {
        bullishPoints++;
        analysis.push('Price is below lower Keltner Channel, potential oversold condition');
      }
    }
  }

  // Williams %R analysis
  if (typeof indicatorValues.williamsR === 'number') {
    if (indicatorValues.williamsR < -80) {
      bullishPoints++;
      analysis.push('Williams %R indicates oversold conditions, potential bullish reversal');
    } else if (indicatorValues.williamsR > -20) {
      bearishPoints++;
      analysis.push('Williams %R indicates overbought conditions, potential bearish reversal');
    }
  }

  const overallSentiment = bullishPoints > bearishPoints ? 'Bullish' : (bearishPoints > bullishPoints ? 'Bearish' : 'Neutral');
  const confidenceScore = (bullishPoints + bearishPoints) > 0 ? Math.abs(bullishPoints - bearishPoints) / (bullishPoints + bearishPoints) : 0;

  return {
    sentiment: overallSentiment,
    confidence: confidenceScore,
    analysis: analysis,
  };
};