// src/pages/ChartImagePage.js

import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import fetchChartData from '../utils/fetchChartData';

// Import all necessary components
import PriceChart from '../components/PriceChart';
import OrderBook from '../components/OrderBook';
import RecentTrades from '../components/RecentTrades';
import ScalpingIndicatorChart from '../components/ScalpingIndicatorChart';
import VolumeChart from '../components/VolumeChart';
import ChannelPatternChart from '../components/ChannelPatternChart';
import HarmonicPatternsChart from '../components/HarmonicPatternsChart';
import HeadAndShouldersChart from '../components/HeadAndShouldersChart';
import MacdChart from '../components/MacdChart';
import RsiChart from '../components/RsiChart';
import StochRSIChart from '../components/StochRSIChart';
import StochasticChart from '../components/StochasticChart';
import DmiChart from '../components/DmiChart';
import OBVChart from '../components/OBVChart';
import ATRChart from '../components/ATRChart';
import BollingerBandsChart from '../components/BollingerBandsChart';
import IchimokuCloudChart from '../components/IchimokuCloudChart';
import FibonacciLevelsChart from '../components/FibonacciLevelsChart';
import SupportResistanceLevelsChart from '../components/SupportResistanceLevelsChart';
import DivergenceChart from '../components/DivergenceChart';
import ConsensusChart from '../components/ConsensusChart';
import LiquiditySwingsChart from '../components/LiquiditySwingsChart';
import VwapMaChart from '../components/VwapMaChart';
import TDSequentialChart from '../components/TDSequentialChart';
import PivotPointsChart from '../components/PivotPointsChart';
import ParabolicSARChart from '../components/ParabolicSARChart';
import ChaikinMoneyFlowChart from '../components/ChaikinMoneyFlowChart';
import CCIChart from '../components/CCIChart';
import KeltnerChannelsChart from '../components/KeltnerChannelsChart';
import WilliamsRChart from '../components/WilliamsRChart';
import MoneyFlowIndexChart from '../components/MoneyFlowIndexChart';
import OnBalanceVolumeChart from '../components/OnBalanceVolumeChart';
import FibonacciTimeZonesChart from '../components/FibonacciTimeZonesChart';
import HeikinAshiChart from '../components/HeikinAshiChart';
import AccumulationDistributionLineChart from '../components/AccumulationDistributionLineChart';
import IndicatorValues from '../components/IndicatorValues';
import FibonacciLevels from '../components/FibonacciLevels';
import SupportResistanceLevels from '../components/SupportResistanceLevels';
import AISentiment from '../components/AISentiment';
import MacdCrossoverSignal from '../components/MacdCrossoverSignal';
import PivotPointsRChart from '../components/PivotPointsRChart';

const ChartImagePage = () => {
  const location = useLocation();
  const [chartData, setChartData] = useState(null);
  const [error, setError] = useState(null);
  const [hoverValues, setHoverValues] = useState(null);

  const fetchDataAndRenderChart = useCallback(async () => {
    const searchParams = new URLSearchParams(location.search);
    const chartType = searchParams.get('chart');
    const timeframe = searchParams.get('timeframe');
    const coin = searchParams.get('coin');
  
    if (!chartType || !timeframe || !coin) {
      setError('Missing required parameters');
      return;
    }
  
    try {
      const data = await fetchChartData(chartType, timeframe, coin);
      setChartData(data);

      if (!data || data.candleData.length === 0) {
        setError('No data available');
        return;
      }
    } catch (err) {
      console.error('Error in fetchDataAndRenderChart:', err);
      setError(err.message || 'Failed to fetch or render chart data');
    }
  }, [location]);

  useEffect(() => {
    fetchDataAndRenderChart();
  }, [fetchDataAndRenderChart]);

  const handleConsensusError = (errorMessage) => {
    console.error('Consensus Chart Error:', errorMessage);
    setError(errorMessage);
  };

  const handleChartHover = (values) => {
    setHoverValues(values);
  };

  const renderChart = () => {
    if (error) {
      return <div className="container mx-auto mt-28 p-4">Error: {error}</div>;
    }
  
    if (!chartData) {
      return <div className="container mx-auto mt-28 p-4">Loading...</div>;
    }
  
    const { chartType, timeframe, coin, candleData, orderBook, recentTrades, indicators, aiSentiment, marketAssessment, combinedAnalysis, macdCrossover, coinStats } = chartData;
  
    const commonProps = {
      data: candleData,
      timeframe: timeframe,
      szDecimals: coinStats.szDecimals,
      isSpot: coinStats.isSpot,
      actualDecimals: coinStats.actualDecimals,
    };
  
    switch (chartType.toLowerCase()) {
      case 'overview':
        return (
          <>
            <PriceChart {...commonProps} />
            {orderBook ? (
              <OrderBook orderBook={orderBook} szDecimals={coinStats.szDecimals} isSpot={coinStats.isSpot} />
            ) : (
              <div>Order Book data not available</div>
            )}
            {recentTrades && recentTrades.length > 0 ? (
              <RecentTrades trades={recentTrades} szDecimals={coinStats.szDecimals} isSpot={coinStats.isSpot} />
            ) : (
              <div>Recent Trades data not available</div>
            )}
          </>
        );
      case 'scalpingindicator':
        return <ScalpingIndicatorChart {...commonProps} />;
      case 'volume':
        return <VolumeChart {...commonProps} />;
      case 'channelpattern':
        return <ChannelPatternChart {...commonProps} />;
      case 'harmonicpatterns':
        return <HarmonicPatternsChart {...commonProps} />;
      case 'headandshoulders':
        return <HeadAndShouldersChart {...commonProps} />;
      case 'macd':
        return <MacdChart {...commonProps} />;
      case 'rsi':
        return <RsiChart {...commonProps} />;
      case 'stochrsi':
        return <StochRSIChart {...commonProps} />;
      case 'stochastic':
        return <StochasticChart {...commonProps} stochasticData={indicators.stochastic} />;
      case 'dmi':
        return <DmiChart {...commonProps} />;
      case 'obv':
        return <OBVChart {...commonProps} obvData={indicators.obv} />;
      case 'atr':
        return <ATRChart {...commonProps} atrData={indicators.atr} />;
      case 'bollingerbands':
        return <BollingerBandsChart {...commonProps} />;
      case 'ichimokucloud':
        return <IchimokuCloudChart {...commonProps} ichimokuData={indicators.ichimoku} />;
      case 'fibonaccilevels':
        return <FibonacciLevelsChart {...commonProps} fibonacciLevels={indicators.fibonacci} />;
      case 'supportresistance':
        return <SupportResistanceLevelsChart {...commonProps} levels={indicators.supportResistance} />;
      case 'divergence':
        return <DivergenceChart {...commonProps} rsiData={indicators.rsi} />;
      case 'consensus':
        return <ConsensusChart {...commonProps} indicators={indicators} onError={handleConsensusError} volume={chartData.volume} />;
      case 'liquidityswings':
        return <LiquiditySwingsChart {...commonProps} />;
      case 'vwapma':
        return <VwapMaChart {...commonProps} vwap={indicators.vwap} ma5={indicators.ma.ma5} ma10={indicators.ma.ma10} ma20={indicators.ma.ma20} ma50={indicators.ma.ma50} ma100={indicators.ma.ma100} ma200={indicators.ma.ma200} onHover={handleChartHover} />;
      case 'tdsequential':
        return <TDSequentialChart {...commonProps} />;
      case 'pivotpoints':
        return <PivotPointsChart {...commonProps} />;
      case 'parabolicsar':
        return <ParabolicSARChart {...commonProps} />;
      case 'chaikinmoneyflow':
        return <ChaikinMoneyFlowChart {...commonProps} />;
      case 'cci':
        return <CCIChart {...commonProps} />;
      case 'keltnerchannels':
        return <KeltnerChannelsChart {...commonProps} />;
      case 'williamsr':
        return <WilliamsRChart {...commonProps} />;
      case 'moneyflowindex':
        return <MoneyFlowIndexChart {...commonProps} />;
      case 'onbalancevolume':
        return <OnBalanceVolumeChart {...commonProps} />;
      case 'fibonaccitimezones':
        return <FibonacciTimeZonesChart {...commonProps} />;
      case 'heikinashi':
        return <HeikinAshiChart {...commonProps} />;
      case 'ppointrlevels':
        return <PivotPointsRChart {...commonProps} />;
      case 'accumulationdistribution':
        return <AccumulationDistributionLineChart {...commonProps} />;
      case 'indicatorvalues':
        return <IndicatorValues indicatorValues={indicators} szDecimals={coinStats.szDecimals} isSpot={coinStats.isSpot} />;
      case 'fibonaccilevels':
        return <FibonacciLevels levels={indicators.fibonacci} szDecimals={coinStats.szDecimals} isSpot={coinStats.isSpot} />;
      case 'supportresistancelevels':
        return <SupportResistanceLevels levels={indicators.supportResistance} szDecimals={coinStats.szDecimals} isSpot={coinStats.isSpot} />;
      case 'aianalysis':
        return (
          <>
            <div className="mb-8 bg-white shadow-md rounded p-4">
              <h3 className="text-xl font-semibold mb-2">Market Assessment</h3>
              <p className="text-lg">{marketAssessment}</p>
            </div>
            <div className="mb-8 bg-white shadow-md rounded p-4">
              <h3 className="text-xl font-semibold mb-2">Comprehensive Technical Analysis</h3>
              {combinedAnalysis && (
                <>
                  <p className="font-bold">Overall Sentiment: <span className={combinedAnalysis.sentiment === 'Bullish' ? 'text-green-600' : 'text-red-600'}>{combinedAnalysis.sentiment}</span></p>
                  <p>Confidence: {(combinedAnalysis.confidence * 100).toFixed(2)}%</p>
                  <ul className="list-disc pl-5 mt-2">
                    {combinedAnalysis.analysis.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <AISentiment sentiment={aiSentiment} timeframe={timeframe} />
            <MacdCrossoverSignal macdCrossover={macdCrossover} />
          </>
        );
      default:
        return <div>Unsupported chart type: {chartType}</div>;
    }
  };

  return (
    <div className="container mx-auto mt-16 p-4">
      <h2 className="text-2xl font-bold mb-4">
        {chartData ? `${chartData.coin} ${chartData.chartType} Chart (${chartData.timeframe})` : 'Loading...'}
      </h2>
      {error ? (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      ) : (
        renderChart()
      )}
      {hoverValues && (
        <div className="mt-4 bg-gray-100 p-4 rounded">
          <h3 className="font-bold">Hover Values:</h3>
          <pre>{JSON.stringify(hoverValues, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default ChartImagePage;