import React, { useEffect, useRef, useState } from 'react';
import { createChart, LineStyle } from 'lightweight-charts';

const PivotPointsRChart = ({ data, timeframe }) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const [currentSignal, setCurrentSignal] = useState('');
  const [settings, setSettings] = useState({
    length: 16,
    showRegular: true,
    showMissed: false,
    regularHighColor: '#ef5350',
    regularLowColor: '#26a69a',
    missedHighColor: '#ef5350',
    missedLowColor: '#26a69a',
    labelColor: '#ffffff',
  });

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 400,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: {
        mode: 0,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
    });

    chartRef.current = chart;

    const candleSeries = chart.addCandlestickSeries();
    candleSeries.setData(data);

    const calculatePivots = () => {
      const pivots = [];
      const { length } = settings;

      for (let i = length; i < data.length - length; i++) {
        const highPivot = Math.max(...data.slice(i - length, i + length + 1).map(d => d.high));
        const lowPivot = Math.min(...data.slice(i - length, i + length + 1).map(d => d.low));

        if (data[i].high === highPivot) {
          pivots.push({ time: data[i].time, value: highPivot, type: 'high' });
        }
        if (data[i].low === lowPivot) {
          pivots.push({ time: data[i].time, value: lowPivot, type: 'low' });
        }
      }

      return pivots;
    };

    const pivots = calculatePivots();

    const regularPivotSeries = chart.addLineSeries({
      color: settings.regularHighColor,
      lineWidth: 2,
      lastValueVisible: false,
    });

    const missedPivotSeries = chart.addLineSeries({
      color: settings.missedHighColor,
      lineStyle: LineStyle.Dashed,
      lineWidth: 2,
      lastValueVisible: false,
    });

    const drawPivots = () => {
      const regularPivots = [];
      const missedPivots = [];
      const markers = [];
      let lastPivot = null;
    
      pivots.forEach((pivot, index) => {
        const isValidPivot = index === 0 || index === pivots.length - 1 || (
          (pivot.type === 'high' && pivot.value > pivots[index - 1].value && pivot.value > pivots[index + 1].value) ||
          (pivot.type === 'low' && pivot.value < pivots[index - 1].value && pivot.value < pivots[index + 1].value)
        );
    
        if (isValidPivot) {
          regularPivots.push(pivot);
          
          // Add marker
          markers.push({
            time: pivot.time,
            position: pivot.type === 'high' ? 'aboveBar' : 'belowBar',
            color: pivot.type === 'high' ? 'red' : 'green',
            shape: 'circle',
            text: pivot.type === 'high' ? 'P' : 'B'
          });
    
          // Set line color based on direction
          if (lastPivot) {
            const lineColor = pivot.value > lastPivot.value ? 'green' : 'red';
            regularPivotSeries.setData([
              { time: lastPivot.time, value: lastPivot.value, color: lineColor },
              { time: pivot.time, value: pivot.value, color: lineColor }
            ]);
          }
          lastPivot = pivot;
        } else {
          missedPivots.push(pivot);
        }
      });
    
      // Handle the last pivot point separately if it wasn't already included
      if (lastPivot !== pivots[pivots.length - 1]) {
        const finalPivot = pivots[pivots.length - 1];
        if (finalPivot && (finalPivot.type === 'high' || finalPivot.type === 'low')) {
          regularPivots.push(finalPivot);
          markers.push({
            time: finalPivot.time,
            position: finalPivot.type === 'high' ? 'aboveBar' : 'belowBar',
            color: finalPivot.type === 'high' ? 'red' : 'green',
            shape: 'circle',
            text: finalPivot.type === 'high' ? 'P' : 'B'
          });
        }
      }
    
      // Update current signal based on the last marker
      if (markers.length > 0) {
        const lastMarker = markers[markers.length - 1];
        setCurrentSignal(lastMarker.text === 'B' ? 'Buy' : 'Sell');
      }

      if (settings.showRegular) {
        regularPivotSeries.setData(regularPivots);
        candleSeries.setMarkers(markers);
      } else {
        regularPivotSeries.setData([]);
        candleSeries.setMarkers([]);
      }
    
      if (settings.showMissed) {
        missedPivotSeries.setData(missedPivots);
      } else {
        missedPivotSeries.setData([]);
      }
    };

    drawPivots();

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, settings]);

  const handleSettingChange = (setting, value) => {
    setSettings(prevSettings => ({ ...prevSettings, [setting]: value }));
  };

  return (
    <div>
      <div ref={chartContainerRef} />
      <div className="mt-4 mb-4 chart-context-main">
        <p className={`text-lg font-semibold ${currentSignal === 'Buy' ? 'text-green-600' : 'text-red-600'}`}>
          Current Signal: {currentSignal}
        </p>
      </div>
      <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2">Settings</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block">Pivot Length</label>
            <input
              type="number"
              value={settings.length}
              onChange={(e) => handleSettingChange('length', parseInt(e.target.value))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block">Show Regular Pivots</label>
            <input
              type="checkbox"
              checked={settings.showRegular}
              onChange={(e) => handleSettingChange('showRegular', e.target.checked)}
              className="mt-2"
            />
          </div>
          <div>
            <label className="block">Show Missed Pivots</label>
            <input
              type="checkbox"
              checked={settings.showMissed}
              onChange={(e) => handleSettingChange('showMissed', e.target.checked)}
              className="mt-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PivotPointsRChart;