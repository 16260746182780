// src/components/PriceForecastChart.js
import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';

const PriceForecastChart = ({ candleData, coin, timeframe }) => {
  const [forecastRange, setForecastRange] = useState('3 days');
  const [forecastData, setForecastData] = useState(null);

  useEffect(() => {
    calculateForecast();
  }, [candleData, forecastRange]);

  const calculateForecast = () => {
    if (!candleData || candleData.length === 0) return;

    const lastPrice = candleData[candleData.length - 1].close;
    const historicalPrices = candleData.map(d => d.close);
    
    // Simple moving average calculation
    const ma = historicalPrices.slice(-20).reduce((sum, price) => sum + price, 0) / 20;
    
    // Calculate volatility
    const returns = historicalPrices.slice(1).map((price, i) => 
      Math.log(price / historicalPrices[i])
    );
    const volatility = Math.sqrt(returns.reduce((sum, ret) => sum + ret * ret, 0) / returns.length) * Math.sqrt(252);

    // Generate forecast data
    const forecastDays = getForecastDays(forecastRange);
    const forecast = [];
    let currentDate = new Date(candleData[candleData.length - 1].time * 1000);

    for (let i = 0; i <= forecastDays; i++) {
      const randomWalk = Math.random() - 0.5;
      const price = lastPrice * (1 + randomWalk * volatility / Math.sqrt(252));
      forecast.push({
        time: currentDate.getTime() / 1000,
        price: price,
        upper: price * (1 + volatility / Math.sqrt(252)),
        lower: price * (1 - volatility / Math.sqrt(252)),
      });
      currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    }

    setForecastData(forecast);
  };

  const getForecastDays = (range) => {
    switch (range) {
      case 'Tomorrow': return 1;
      case '3 days': return 3;
      case '5 days': return 5;
      case '1 week': return 7;
      case '2 weeks': return 14;
      case '1 month': return 30;
      default: return 3;
    }
  };

  if (!forecastData) return null;

  const forecastTrace = {
    x: forecastData.map(d => new Date(d.time * 1000)),
    y: forecastData.map(d => d.price),
    type: 'scatter',
    mode: 'lines+markers+text',
    name: 'Forecast',
    line: { color: 'red', width: 3 },
    marker: { size: 8 },
    text: forecastData.map(d => `$${d.price.toFixed(2)}`),
    textposition: 'top center',
    textfont: { size: 10 },
  };

  const upperBoundTrace = {
    x: forecastData.map(d => new Date(d.time * 1000)),
    y: forecastData.map(d => d.upper),
    type: 'scatter',
    mode: 'lines',
    name: 'Upper Bound',
    line: { color: 'rgba(0, 255, 0, 0.2)' },
    fill: 'tonexty'
  };

  const lowerBoundTrace = {
    x: forecastData.map(d => new Date(d.time * 1000)),
    y: forecastData.map(d => d.lower),
    type: 'scatter',
    mode: 'lines',
    name: 'Lower Bound',
    line: { color: 'rgba(255, 0, 0, 0.2)' },
    fill: 'tonexty'
  };

  return (
    <div className="w-full">
      <div className="mb-4 flex items-center">
        <label htmlFor="dateRange" className="mr-2 font-medium">Select Date Range:</label>
        <select 
          id="dateRange"
          value={forecastRange} 
          onChange={(e) => setForecastRange(e.target.value)}
          className="p-2 border rounded"
        >
          <option>Tomorrow</option>
          <option>3 days</option>
          <option>5 days</option>
          <option>1 week</option>
          <option>2 weeks</option>
          <option>1 month</option>
        </select>
      </div>
      <div className="w-full">
        <Plot
          data={[forecastTrace, upperBoundTrace, lowerBoundTrace]}
          layout={{
            autosize: true,
            xaxis: { 
              title: 'Date',
              range: [
                new Date(forecastData[0].time * 1000),
                new Date(forecastData[forecastData.length - 1].time * 1000)
              ]
            },
            yaxis: { title: 'Price' },
            height: 500,
            legend: { orientation: 'h', y: -0.2 },
            margin: { l: 50, r: 50, t: 30, b: 50 }
          }}
          config={{
            responsive: true
          }}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  );
};

export default PriceForecastChart;