// MacdCrossoverSignal.js

import React from 'react';

const MacdCrossoverSignal = ({ macdCrossover }) => {
  const formatTimeSinceCrossover = (timeSinceCrossover) => {
    if (!timeSinceCrossover) return 'N/A';

    const seconds = Math.floor(timeSinceCrossover / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days > 1 ? 's' : ''}`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''}`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    return `${seconds} second${seconds > 1 ? 's' : ''}`;
  };

  return (
    <div className="bg-white shadow-md rounded p-4 mb-8">
      <h3 className="text-xl font-semibold mb-2">Bullish or Bearish Crossover</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h4 className="text-sm font-semibold text-gray-500">MACD Signal</h4>
          <p className={`text-lg font-bold ${macdCrossover.signal === 'Bullish' ? 'text-green-600' : 'text-red-600'}`}>
            {macdCrossover.signal}
          </p>
        </div>
        <div>
          <h4 className="text-sm font-semibold text-gray-500">Crossover Time</h4>
          <p className="text-lg font-bold">
            {macdCrossover.time ? macdCrossover.time.toLocaleString() : 'N/A'}
          </p>
        </div>
        <div>
          <h4 className="text-sm font-semibold text-gray-500">Time Since Crossover</h4>
          <p className="text-lg font-bold">
            {formatTimeSinceCrossover(macdCrossover.timeSinceCrossover)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MacdCrossoverSignal;