import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { detectHeadAndShoulders, predictTrend } from '../utils/indicators';

const HeadAndShouldersChart = ({ data, timeframe, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();
  const [selectedPattern, setSelectedPattern] = useState(null);
  const [trendPrediction, setTrendPrediction] = useState(null);
  const [headAndShouldersData, setHeadAndShouldersData] = useState(null);

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals)),
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
    });

    const candleSeries = chart.addCandlestickSeries();
    candleSeries.setData(data);

    const { patterns, breakouts } = detectHeadAndShoulders(data);
    setHeadAndShouldersData({ patterns, breakouts });

    // Add Head and Shoulders patterns
    patterns.forEach((pattern) => {
      const patternSeries = chart.addLineSeries({
        color: pattern.type === 'head_and_shoulders' ? 'rgba(255, 0, 0, 0.5)' : 'rgba(0, 255, 0, 0.5)',
        lineWidth: 2,
        lineStyle: 2,
      });

      patternSeries.setData([
        { time: pattern.left_shoulder.time, value: pattern.left_shoulder.price },
        { time: pattern.head.time, value: pattern.head.price },
        { time: pattern.right_shoulder.time, value: pattern.right_shoulder.price },
      ]);
    });

    // Prepare all markers
    let allMarkers = patterns.flatMap(pattern => [
      { time: pattern.left_shoulder.time, position: 'belowBar', color: '#4CAF50', shape: 'circle', text: 'LS' },
      { time: pattern.head.time, position: 'belowBar', color: '#2196F3', shape: 'circle', text: 'H' },
      { time: pattern.right_shoulder.time, position: 'belowBar', color: '#4CAF50', shape: 'circle', text: 'RS' },
    ]);

    // Add breakout markers
    const breakoutMarkers = breakouts.map(breakout => ({
      time: breakout.time,
      position: breakout.type === 'breakdown' ? 'belowBar' : 'aboveBar',
      color: breakout.type === 'breakdown' ? '#FF0000' : '#00FF00',
      shape: 'circle',
      text: breakout.type === 'breakdown' ? 'BD' : 'BU',
    }));

    allMarkers = [...allMarkers, ...breakoutMarkers];

    // Sort markers by time in ascending order
    allMarkers.sort((a, b) => a.time - b.time);

    // Set all markers at once
    candleSeries.setMarkers(allMarkers);

    chart.subscribeCrosshairMove((param) => {
      if (param.time) {
        const foundPattern = patterns.find(p => 
          p.left_shoulder.time <= param.time && 
          p.right_shoulder.time >= param.time
        );
        setSelectedPattern(foundPattern || null);
      } else {
        setSelectedPattern(null);
      }
    });

    // Predict trend based on the latest pattern
    if (patterns.length > 0 && breakouts.length > 0) {
      const latestPattern = patterns[patterns.length - 1];
      const prediction = predictTrend(latestPattern, data, breakouts);
      setTrendPrediction(prediction);
    }

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, timeframe, szDecimals, isSpot, actualDecimals]);

  return (
    <div>
      <div ref={chartContainerRef} />
      {selectedPattern && (
        <div className="mt-4 p-4 bg-gray-100 rounded">
          <h4 className="font-bold">Selected Pattern Details</h4>
          <p>Type: {selectedPattern.type === 'head_and_shoulders' ? 'Head and Shoulders' : 'Inverse Head and Shoulders'}</p>
          <p>Left Shoulder: {new Date(selectedPattern.left_shoulder.time * 1000).toLocaleString()}</p>
          <p>Head: {new Date(selectedPattern.head.time * 1000).toLocaleString()}</p>
          <p>Right Shoulder: {new Date(selectedPattern.right_shoulder.time * 1000).toLocaleString()}</p>
          <p>Neckline Price: {((selectedPattern.left_shoulder.price + selectedPattern.right_shoulder.price) / 2).toFixed(2)}</p>
        </div>
      )}
      {trendPrediction && (
        <div className="mt-4 p-4 bg-gray-100 rounded chart-context-main">
          <h4 className="font-bold">Trend Prediction</h4>
          <p>Direction: <span className={trendPrediction.direction === 'Bullish' ? 'text-green-600' : 'text-red-600'}>{trendPrediction.direction}</span></p>
          <p>Confidence: {(trendPrediction.confidence * 100).toFixed(2)}%</p>
          <p>Target Price: {trendPrediction.targetPrice.toFixed(2)}</p>
        </div>
      )}
      {headAndShouldersData && (
        <div className="p-4 chart-context-more">
          <p>Total patterns detected: {headAndShouldersData.patterns.length}</p>
          <ul>
            {headAndShouldersData.patterns.map((pattern, index) => (
              <li key={index}>
                {pattern.type === 'head_and_shoulders' ? 'Head and Shoulders' : 'Inverse Head and Shoulders'} pattern:
                LS: {new Date(pattern.left_shoulder.time * 1000).toLocaleString()},
                H: {new Date(pattern.head.time * 1000).toLocaleString()},
                RS: {new Date(pattern.right_shoulder.time * 1000).toLocaleString()}
              </li>
            ))}
          </ul>
          <p>Breakouts: {headAndShouldersData.breakouts.length}</p>
          <ul>
            {headAndShouldersData.breakouts.sort((a, b) => a.time - b.time).map((breakout, index) => (
              <li key={index}>
                {breakout.type === 'breakdown' ? 'Breakdown' : 'Breakup'} at {new Date(breakout.time * 1000).toLocaleString()}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default HeadAndShouldersChart;