import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTab } from '../TabContext';
import CoinStatsSummary from '../components/CoinStatsSummary';
import AISentiment from '../components/AISentiment';
import TimeframeSelector from '../components/TimeframeSelector';
import PriceChart from '../components/PriceChart';
import PriceForecastChart from '../components/PriceForecastChart';
import ConsensusChart from '../components/ConsensusChart';
import MacdChart from '../components/MacdChart';
import RsiChart from '../components/RsiChart';
import StochRSIChart from '../components/StochRSIChart';
import DmiChart from '../components/DmiChart';
import BollingerBandsChart from '../components/BollingerBandsChart';
import TDSequentialChart from '../components/TDSequentialChart';
import VwapMaChart from '../components/VwapMaChart';
import VolumeChart from '../components/VolumeChart';
import IndicatorValues from '../components/IndicatorValues';
import FibonacciLevels from '../components/FibonacciLevels';
import SupportResistanceLevels from '../components/SupportResistanceLevels';
import MacdCrossoverSignal from '../components/MacdCrossoverSignal';
import OrderBook from '../components/OrderBook';
import RecentTrades from '../components/RecentTrades';
import FibonacciLevelsChart from '../components/FibonacciLevelsChart';
import SupportResistanceLevelsChart from '../components/SupportResistanceLevelsChart';
import DivergenceChart from '../components/DivergenceChart';
import ChannelPatternChart from '../components/ChannelPatternChart';
import LiquiditySwingsChart from '../components/LiquiditySwingsChart';
import HeadAndShouldersChart from '../components/HeadAndShouldersChart';
import StochasticChart from '../components/StochasticChart';
import OBVChart from '../components/OBVChart';
import IchimokuCloudChart from '../components/IchimokuCloudChart';
import ATRChart from '../components/ATRChart';
import HarmonicPatternsChart from '../components/HarmonicPatternsChart';

import PivotPointsChart from '../components/PivotPointsChart';
import ParabolicSARChart from '../components/ParabolicSARChart';
import ChaikinMoneyFlowChart from '../components/ChaikinMoneyFlowChart';
import CCIChart from '../components/CCIChart';
//import VolumeProfileChart from '../components/VolumeProfileChart';
import KeltnerChannelsChart from '../components/KeltnerChannelsChart';
import WilliamsRChart from '../components/WilliamsRChart';
import MoneyFlowIndexChart from '../components/MoneyFlowIndexChart';
import OnBalanceVolumeChart from '../components/OnBalanceVolumeChart';
import FibonacciTimeZonesChart from '../components/FibonacciTimeZonesChart';
import HeikinAshiChart from '../components/HeikinAshiChart';
import AccumulationDistributionLineChart from '../components/AccumulationDistributionLineChart';
import ScalpingIndicatorChart from '../components/ScalpingIndicatorChart';
import PivotPointsRChart from '../components/PivotPointsRChart';

import { 
  calculateIndicators, 
  getTimeframeInMilliseconds, 
  detectMACDCrossover, 
  getLatestIndicatorValues,
  getMarketTrend,
  getMarketTrendColor,
  calculateTDSequential,
  generateAISentiment,
  calculatePriceForecast,
  generateTradingRecommendation,
  analyzeMarketSentiment,
  generateAdditionalInsights,
  calculateConsensusSignals,
  detectHeadAndShoulders,
  predictTrend,
  calculateStochastic,
  calculateOBV,
  calculateIchimokuCloud,
  calculateATR,
  detectHarmonicPatterns
} from '../utils/indicators';

const CoinDetails = () => {
  const { activeTab } = useTab();
  const { coin } = useParams();
  const [candleData, setCandleData] = useState([]);
  const [orderBook, setOrderBook] = useState({ bids: [], asks: [] });
  const [trades, setTrades] = useState([]);
  const [coinStats, setCoinStats] = useState(null);
  const [timeframe, setTimeframe] = useState('1h');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [indicatorValues, setIndicatorValues] = useState({});
  const [macdCrossover, setMacdCrossover] = useState({ signal: '', time: null });
  const [aiSentiment, setAISentiment] = useState(null);
  const [hoverValues, setHoverValues] = useState(null);
  const [marketAssessment, setMarketAssessment] = useState('');
  const [divergenceError, setDivergenceError] = useState(null);
  const [consensusError, setConsensusError] = useState(null);
  const [consensusSignals, setConsensusSignals] = useState([]);
  const [headAndShouldersData, setHeadAndShouldersData] = useState(null);
  const [trendPrediction, setTrendPrediction] = useState(null);
  const [combinedAnalysis, setCombinedAnalysis] = useState(null);
  const [stochasticData, setStochasticData] = useState(null);
  const [obvData, setOBVData] = useState(null);
  const [ichimokuData, setIchimokuData] = useState(null);
  const [atrData, setATRData] = useState(null);
  const [harmonicPatterns, setHarmonicPatterns] = useState([]);
  const [isWebSocketReady, setIsWebSocketReady] = useState(false);

  const fetchHistoricalData = useCallback(async () => {
    try {
      setIsLoading(true);
      const endTime = Date.now();
      const startTime = endTime - (500 * getTimeframeInMilliseconds(timeframe));
      
      const response = await axios.post('https://api.hyperliquid.xyz/info', {
        type: 'candleSnapshot',
        req: { coin, interval: timeframe, startTime, endTime }
      });

      const formattedData = response.data.map(candle => ({
        time: Math.floor(candle.t / 1000),
        open: parseFloat(candle.o),
        high: parseFloat(candle.h),
        low: parseFloat(candle.l),
        close: parseFloat(candle.c),
        volume: parseFloat(candle.v)
      }));
      
      const sortedData = formattedData.sort((a, b) => a.time - b.time);
      setCandleData(sortedData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching historical data:', error);
      setError('Failed to fetch historical data. Please try again later.');
      setIsLoading(false);
    }
  }, [coin, timeframe]);

  const fetchCoinStats = useCallback(async () => {
    try {
      const response = await axios.post('https://api.hyperliquid.xyz/info', {
        type: 'metaAndAssetCtxs'
      });
      const universe = response.data[0].universe;
      const assetCtxs = response.data[1];
      
      const coinIndex = universe.findIndex(asset => asset.name === coin);
      if (coinIndex !== -1) {
        const coinMeta = universe[coinIndex];
        const coinData = assetCtxs[coinIndex];
        
        const markPx = parseFloat(coinData.markPx);
        const actualDecimals = (markPx.toString().split('.')[1] || '').length;
        
        const stats = {
          name: coin,
          markPx: markPx,
          percentChange: ((markPx / parseFloat(coinData.prevDayPx)) - 1) * 100,
          dayNtlVlm: parseFloat(coinData.dayNtlVlm),
          prevDayPx: parseFloat(coinData.prevDayPx),
          openInterest: parseFloat(coinData.openInterest),
          funding: coinData.funding,
          maxLeverage: coinMeta.maxLeverage,
          szDecimals: coinMeta.szDecimals,
          actualDecimals: actualDecimals,
          isSpot: false  // Assuming all assets are perps, update this if needed
        };
        setCoinStats(stats);
      } else {
        console.log('Coin data not found:', coin);
        setError(`Coin data not found for ${coin}`);
      }
    } catch (error) {
      console.error('Error fetching coin statistics:', error);
      setError('Failed to fetch coin statistics. Please try again later.');
    }
  }, [coin]);

  const updateCandleData = useCallback((newCandle) => {
    const updatedCandle = {
      time: Math.floor(newCandle.t / 1000),
      open: parseFloat(newCandle.o),
      high: parseFloat(newCandle.h),
      low: parseFloat(newCandle.l),
      close: parseFloat(newCandle.c),
      volume: parseFloat(newCandle.v)
    };

    setCandleData(prevData => {
      const updatedData = [...prevData];
      const existingIndex = updatedData.findIndex(candle => candle.time === updatedCandle.time);
      
      if (existingIndex !== -1) {
        updatedData[existingIndex] = updatedCandle;
      } else {
        updatedData.push(updatedCandle);
      }
      
      return updatedData.sort((a, b) => a.time - b.time);
    });
  }, []);

  const handleWebSocketMessage = useCallback((message) => {
    switch (message.channel) {
      case 'candle':
        updateCandleData(message.data);
        break;
      case 'l2Book':
        if (message.data && message.data.levels) {
          setOrderBook({
            bids: message.data.levels[0] || [],
            asks: message.data.levels[1] || []
          });
        }
        break;
      case 'trades':
        if (Array.isArray(message.data)) {
          setTrades(prevTrades => {
            const newTrades = [...message.data, ...prevTrades].slice(0, 50);
            return newTrades.sort((a, b) => b.time - a.time);
          });
        }
        break;
      default:
        break;
    }
  }, [updateCandleData]);

  const handleChartHover = (values) => {
    setHoverValues(values);
  };

  useEffect(() => {
    fetchHistoricalData();
    fetchCoinStats();
    const statsInterval = setInterval(fetchCoinStats, 60000);

    const ws = new WebSocket('wss://api.hyperliquid.xyz/ws');

    ws.onopen = () => {
      console.log('WebSocket connected');
      ws.send(JSON.stringify({
        method: 'subscribe',
        subscription: { type: 'candle', coin, interval: timeframe }
      }));
      ws.send(JSON.stringify({
        method: 'subscribe',
        subscription: { type: 'l2Book', coin }
      }));
      ws.send(JSON.stringify({
        method: 'subscribe',
        subscription: { type: 'trades', coin }
      }));
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      handleWebSocketMessage(message);
      if (!isWebSocketReady) {
        setIsWebSocketReady(true);
      }
    };

    return () => {
      clearInterval(statsInterval);
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, [coin, timeframe, fetchHistoricalData, fetchCoinStats, handleWebSocketMessage, isWebSocketReady]);

  useEffect(() => {
    if (candleData.length > 0 && coinStats && isWebSocketReady) {
      try {
        const indicators = calculateIndicators(candleData, coinStats.szDecimals, coinStats.isSpot);
        setIndicatorValues(indicators);
        
        const newMacdCrossover = detectMACDCrossover(indicators.macd, candleData, timeframe);
        setMacdCrossover(newMacdCrossover);
    
        const sentiment = generateAISentiment(candleData, indicators, coinStats, orderBook, trades, timeframe, coinStats.szDecimals, coinStats.isSpot);
        setAISentiment(sentiment);
  
        // Calculate Stochastic Oscillator separately
        const stochastic = calculateStochastic(candleData);
        setStochasticData(stochastic);
        
        setIndicatorValues(prevIndicators => ({
          ...prevIndicators,
          ...indicators,
          stochastic: stochastic,
        }));
  
        const obv = calculateOBV(candleData);
        setOBVData(obv);
  
        const ichimoku = calculateIchimokuCloud(candleData);
        setIchimokuData(ichimoku);
  
        const atr = calculateATR(candleData);
        setATRData(atr);
  
        const assessment = assessMarket(indicators, candleData[candleData.length - 1].close);
        setMarketAssessment(assessment);
  
        // Calculate Consensus signals
        const volume = candleData.map(d => d.volume);
        const consensusSignals = calculateConsensusSignals(candleData, indicators, volume, timeframe);
        setConsensusSignals(consensusSignals);
      } catch (error) {
        console.error('Error in indicator calculations:', error);
        setMarketAssessment('Unable to assess market due to insufficient data.');
        setConsensusError('Failed to calculate indicators. Please try again later.');
      }
    }
  }, [candleData, coinStats, orderBook, trades, timeframe, isWebSocketReady]);

  useEffect(() => {
    if (candleData.length > 0 && isWebSocketReady) {
      const { patterns, breakouts } = detectHeadAndShoulders(candleData);
      setHeadAndShouldersData({ patterns, breakouts });

      const patternsHP = detectHarmonicPatterns(candleData);
      setHarmonicPatterns(patternsHP);

      if (patterns.length > 0 && breakouts.length > 0) {
        const latestPattern = patterns[patterns.length - 1];
        const prediction = predictTrend(latestPattern, candleData, breakouts);
        setTrendPrediction(prediction);
      }
    }
  }, [candleData, isWebSocketReady]);

  useEffect(() => {
    if (candleData.length > 0 && indicatorValues.rsi && indicatorValues.rsi.length > 0 && isWebSocketReady) {
      if (candleData.length !== indicatorValues.rsi.length) {
        setDivergenceError("Mismatch in data lengths for divergence calculation");
      } else {
        setDivergenceError(null);
      }
    }
  }, [candleData, indicatorValues.rsi, isWebSocketReady]);

  const handleTimeframeChange = useCallback((newTimeframe) => {
    setTimeframe(newTimeframe);
    fetchHistoricalData();
  }, [setTimeframe, fetchHistoricalData]);

  const formatTimeSinceCrossover = (minutes) => {
    const days = Math.floor(minutes / 1440);
    const hours = Math.floor((minutes % 1440) / 60);
    const remainingMinutes = minutes % 60;
    const seconds = Math.floor((minutes % 1) * 60);

    let result = '';
    if (days > 0) result += `${days}d `;
    if (hours > 0) result += `${hours}h `;
    if (remainingMinutes > 0) result += `${remainingMinutes}m `;
    if (seconds > 0) result += `${seconds}s`;

    return result.trim();
  };

  const getVwapMaStatus = (price, value) => {
    if (!price || !value) return 'N/A';
    const threshold = 0.001; // 0.1% threshold
    const diff = (price - value) / value;
    if (Math.abs(diff) < threshold) return 'Price is near';
    return diff > 0 ? 'Crossed Over' : 'Still Under';
  };

  const getLatestValue = (arr) => {
    if (Array.isArray(arr) && arr.length > 0) {
      const lastValue = arr[arr.length - 1].value;
      return typeof lastValue === 'number' ? lastValue : null;
    }
    return null;
  };

  const assessMarket = (indicators, currentPrice) => {
    let bullishPoints = 0;
    let bearishPoints = 0;

    if (!indicators || !currentPrice) {
      throw new Error('There is insufficient data for market assessment');
    }

    // RSI
    const lastRSI = indicators.rsi && indicators.rsi.length > 0 ? indicators.rsi[indicators.rsi.length - 1].value : null;
    if (lastRSI !== null) {
      if (lastRSI < 30) bullishPoints++;
      if (lastRSI > 70) bearishPoints++;
    }

    // MACD
    if (indicators.macd && indicators.macd.macdLine && indicators.macd.signalLine) {
      const latestMACD = indicators.macd.macdLine[indicators.macd.macdLine.length - 1]?.value;
      const latestSignal = indicators.macd.signalLine[indicators.macd.signalLine.length - 1]?.value;
      if (latestMACD !== undefined && latestSignal !== undefined) {
        if (latestMACD > latestSignal) bullishPoints++;
        if (latestMACD < latestSignal) bearishPoints++;
      }
    }

    // Bollinger Bands
    const latestBB = indicators.bollingerBands && indicators.bollingerBands.length > 0 ? 
      indicators.bollingerBands[indicators.bollingerBands.length - 1] : null;
    if (latestBB && latestBB.lower !== undefined && latestBB.upper !== undefined) {
      if (currentPrice < latestBB.lower) bullishPoints++;
      if (currentPrice > latestBB.upper) bearishPoints++;
    }

    // Moving Averages
    const ma50 = indicators.ma && indicators.ma.ma50 && indicators.ma.ma50.length > 0 ? 
      indicators.ma.ma50[indicators.ma.ma50.length - 1].value : null;
    const ma200 = indicators.ma && indicators.ma.ma200 && indicators.ma.ma200.length > 0 ? 
      indicators.ma.ma200[indicators.ma.ma200.length - 1].value : null;
    if (ma50 !== null && ma200 !== null) {
      if (currentPrice > ma50 && currentPrice > ma200) bullishPoints++;
      if (currentPrice < ma50 && currentPrice < ma200) bearishPoints++;
    }

    // DMI
    if (indicators.dmi && indicators.dmi.adx && indicators.dmi.diPlus && indicators.dmi.diMinus) {
      const latestDMI = indicators.dmi.adx[indicators.dmi.adx.length - 1]?.value;
      if (latestDMI !== undefined && latestDMI > 25) {
        const latestDIPlus = indicators.dmi.diPlus[indicators.dmi.diPlus.length - 1]?.value;
        const latestDIMinus = indicators.dmi.diMinus[indicators.dmi.diMinus.length - 1]?.value;
        if (latestDIPlus !== undefined && latestDIMinus !== undefined) {
          if (latestDIPlus > latestDIMinus) bullishPoints++;
          if (latestDIMinus > latestDIPlus) bearishPoints++;
        }
      }
    }

    if (bullishPoints > bearishPoints) {
      return "This might be a good time to buy. Multiple indicators are showing bullish signals.";
    } else if (bearishPoints > bullishPoints) {
      return "This could be a good time to sell. Multiple indicators are showing bearish signals.";
    } else {
      return "The market is currently showing mixed signals. It might be best to wait for clearer indications before making a decision.";
    }
  };

  const marketTrend = getMarketTrend(trades);
  const marketTrendColor = getMarketTrendColor(marketTrend);

  const formatNumber = (num, decimals) => {
    if (num === null || num === undefined) return 'N/A';
    return Number(num).toFixed(decimals);
  };

  const handleConsensusError = (error) => {
    console.error('Error in ConsensusChart:', error);
    setConsensusError('An error occurred while generating Consensus signals. Please try again later.');
  };

  const combineAnalysis = (indicators, headAndShouldersPrediction, priceData) => {
    if (!indicators || !priceData || priceData.length === 0) {
      console.warn('Invalid input for combineAnalysis');
      return null;
    }
  
    const latestPrice = priceData[priceData.length - 1].close;
    const indicatorValues = getLatestIndicatorValues(indicators);
  
    let bullishPoints = 0;
    let bearishPoints = 0;
    let analysis = [];
  
    // Head and Shoulders analysis
    if (headAndShouldersPrediction && headAndShouldersPrediction.direction) {
      if (headAndShouldersPrediction.direction === 'Bullish') {
        bullishPoints += 2;
        analysis.push(`Head and Shoulders pattern suggests a bullish trend (Confidence: ${(headAndShouldersPrediction.confidence * 100).toFixed(2)}%)`);
      } else {
        bearishPoints += 2;
        analysis.push(`Head and Shoulders pattern suggests a bearish trend (Confidence: ${(headAndShouldersPrediction.confidence * 100).toFixed(2)}%)`);
      }
    }
  
    // RSI analysis
    if (indicatorValues.rsi !== null) {
      if (indicatorValues.rsi < 30) {
        bullishPoints++;
        analysis.push('RSI indicates oversold conditions, potential bullish reversal');
      } else if (indicatorValues.rsi > 70) {
        bearishPoints++;
        analysis.push('RSI indicates overbought conditions, potential bearish reversal');
      }
    }
  
    // MACD analysis
    if (indicatorValues.macd && indicatorValues.macd.macd !== null && indicatorValues.macd.signal !== null) {
      if (indicatorValues.macd.macd > indicatorValues.macd.signal) {
        bullishPoints++;
        analysis.push('MACD is above signal line, indicating bullish momentum');
      } else {
        bearishPoints++;
        analysis.push('MACD is below signal line, indicating bearish momentum');
      }
    }
  
    // Bollinger Bands analysis
    if (indicatorValues.bollingerBands && typeof indicatorValues.bollingerBands === 'object') {
      const { upper, lower } = indicatorValues.bollingerBands;
      if (typeof upper === 'number' && typeof lower === 'number') {
        if (latestPrice > upper) {
          bearishPoints++;
          analysis.push('Price is above upper Bollinger Band, potential overbought condition');
        } else if (latestPrice < lower) {
          bullishPoints++;
          analysis.push('Price is below lower Bollinger Band, potential oversold condition');
        }
      }
    }
  
    // Stochastic RSI analysis
    if (indicatorValues.stochRSI && typeof indicatorValues.stochRSI === 'object') {
      const { k, d } = indicatorValues.stochRSI;
      if (typeof k === 'number' && typeof d === 'number') {
        if (k < 20 && d < 20) {
          bullishPoints++;
          analysis.push('Stochastic RSI indicates oversold conditions, potential bullish reversal');
        } else if (k > 80 && d > 80) {
          bearishPoints++;
          analysis.push('Stochastic RSI indicates overbought conditions, potential bearish reversal');
        }
      }
    }
  
    // DMI analysis
    if (indicatorValues.dmi && typeof indicatorValues.dmi === 'object') {
      const { diPlus, diMinus, adx } = indicatorValues.dmi;
      if (typeof diPlus === 'number' && typeof diMinus === 'number' && typeof adx === 'number') {
        if (diPlus > diMinus && adx > 25) {
          bullishPoints++;
          analysis.push('DMI indicates strong bullish trend');
        } else if (diMinus > diPlus && adx > 25) {
          bearishPoints++;
          analysis.push('DMI indicates strong bearish trend');
        }
      }
    }
  
    // Fibonacci levels analysis
    if (Array.isArray(indicatorValues.fibonacci)) {
      const nearestFibLevel = indicatorValues.fibonacci.find(level => Math.abs(level.value - latestPrice) / latestPrice < 0.01);
      if (nearestFibLevel) {
        analysis.push(`Price is near Fibonacci level ${nearestFibLevel.level}`);
      }
    }
  
    // Channel Pattern analysis
    if (indicatorValues.channelPattern !== null) {
      const upperChannel = indicatorValues.channelPattern.upperChannel;
      const lowerChannel = indicatorValues.channelPattern.lowerChannel;
      if (upperChannel && lowerChannel && upperChannel.length > 0 && lowerChannel.length > 0) {
        if (latestPrice > upperChannel[upperChannel.length - 1].value) {
          bullishPoints++;
          analysis.push('Price is above the upper channel, indicating strong bullish momentum');
        } else if (latestPrice < lowerChannel[lowerChannel.length - 1].value) {
          bearishPoints++;
          analysis.push('Price is below the lower channel, indicating strong bearish momentum');
        }
      }
    }
  
    // Liquidity Swings analysis
    if (indicatorValues.liquiditySwings !== null && indicatorValues.liquiditySwings.type !== null) {
      if (indicatorValues.liquiditySwings.type === 'high') {
        bearishPoints++;
        analysis.push('Recent high liquidity swing, potential for bearish reversal');
      } else if (indicatorValues.liquiditySwings.type === 'low') {
        bullishPoints++;
        analysis.push('Recent low liquidity swing, potential for bullish reversal');
      }
    }
  
    // Chaikin Money Flow analysis
    if (typeof indicatorValues.cmf === 'number') {
      if (indicatorValues.cmf > 0.05) {
        bullishPoints++;
        analysis.push('Chaikin Money Flow is positive, indicating buying pressure');
      } else if (indicatorValues.cmf < -0.05) {
        bearishPoints++;
        analysis.push('Chaikin Money Flow is negative, indicating selling pressure');
      }
    }
  
    // Pivot Points analysis
    if (indicatorValues.pivotPoints && typeof indicatorValues.pivotPoints === 'object') {
      const pivotEntries = Object.entries(indicatorValues.pivotPoints);
      if (pivotEntries.length > 0) {
        const nearestPivot = pivotEntries.reduce((a, b) => Math.abs(b[1] - latestPrice) < Math.abs(a[1] - latestPrice) ? b : a);
        analysis.push(`Price is nearest to ${nearestPivot[0]} pivot point at ${nearestPivot[1]}`);
      }
    }
  
    // VWAP analysis
    if (typeof indicatorValues.vwap === 'number') {
      if (latestPrice > indicatorValues.vwap) {
        bullishPoints++;
        analysis.push('Price is above VWAP, indicating bullish sentiment');
      } else {
        bearishPoints++;
        analysis.push('Price is below VWAP, indicating bearish sentiment');
      }
    }
  
    // Keltner Channels analysis
    if (indicatorValues.keltnerChannels && typeof indicatorValues.keltnerChannels === 'object') {
      const { upper, lower } = indicatorValues.keltnerChannels;
      if (typeof upper === 'number' && typeof lower === 'number') {
        if (latestPrice > upper) {
          bearishPoints++;
          analysis.push('Price is above upper Keltner Channel, potential overbought condition');
        } else if (latestPrice < lower) {
          bullishPoints++;
          analysis.push('Price is below lower Keltner Channel, potential oversold condition');
        }
      }
    }
  
    // Williams %R analysis
    if (typeof indicatorValues.williamsR === 'number') {
      if (indicatorValues.williamsR < -80) {
        bullishPoints++;
        analysis.push('Williams %R indicates oversold conditions, potential bullish reversal');
      } else if (indicatorValues.williamsR > -20) {
        bearishPoints++;
        analysis.push('Williams %R indicates overbought conditions, potential bearish reversal');
      }
    }
  
    const overallSentiment = bullishPoints > bearishPoints ? 'Bullish' : (bearishPoints > bullishPoints ? 'Bearish' : 'Neutral');
    const confidenceScore = (bullishPoints + bearishPoints) > 0 ? Math.abs(bullishPoints - bearishPoints) / (bullishPoints + bearishPoints) : 0;

    return {
      sentiment: overallSentiment,
      confidence: confidenceScore,
      analysis: analysis,
    };
  };

  useEffect(() => {
    if (indicatorValues && trendPrediction && isWebSocketReady) {
      const analysis = combineAnalysis(indicatorValues, trendPrediction, candleData);
      setCombinedAnalysis(analysis);
    }
  }, [indicatorValues, trendPrediction, candleData, isWebSocketReady]);

  if (isLoading) return <div className="text-center mt-32">Loading...</div>;
  if (error) return <div className="text-center mt-32 text-red-500">{error}</div>;

  return (
    <div className="container mx-auto mt-28 p-4">
      <h2 className="text-2xl font-bold mb-4">{coin} Details</h2>
      {isLoading && <div className="text-center mt-32">Loading...</div>}
      {error && <div className="text-center mt-32 text-red-500">{error}</div>}
      {!isWebSocketReady && <div className="text-center mt-32">Waiting for WebSocket data...</div>}
      {coinStats && isWebSocketReady && (
        <>
          <CoinStatsSummary coinStats={coinStats} />
          <TimeframeSelector timeframe={timeframe} onTimeframeChange={handleTimeframeChange} />
          
        {candleData.length > 0 && indicatorValues.rsi && indicatorValues.rsi.length > 0 ? (
        <>
          {activeTab === 'overview' && (
            <>
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Price Forecast based on ({timeframe}) timeframe data</h3>
                <PriceForecastChart 
                  candleData={candleData}
                  coin={coin}
                  timeframe={timeframe}
                />
              </div>
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Price Chart ({timeframe})</h3>
                <PriceChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <OrderBook 
                orderBook={orderBook} 
                szDecimals={coinStats.szDecimals}
                isSpot={coinStats.isSpot}
              />
              <RecentTrades 
                trades={trades} 
                marketTrend={marketTrend} 
                marketTrendColor={marketTrendColor} 
                szDecimals={coinStats.szDecimals}
                isSpot={coinStats.isSpot}
              />
            </>
          )}

          {activeTab === 'viewCharts' && (
            <>
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Pivot Points & Reversal Levels ({timeframe})</h3>
                <PivotPointsRChart
                  data={candleData}
                  timeframe={timeframe}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Head and Shoulders Pattern ({timeframe})</h3>
                <HeadAndShouldersChart 
                  data={candleData}
                  timeframe={timeframe}
                  szDecimals={coinStats.szDecimals}
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Scalping Indicator Chart ({timeframe})</h3>
                <ScalpingIndicatorChart 
                  data={candleData}
                  timeframe={timeframe}
                  szDecimals={coinStats.szDecimals}
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Divergence Chart ({timeframe})</h3>
                {divergenceError ? (
                  <div className="text-red-500">{divergenceError}</div>
                ) : (
                  <DivergenceChart 
                    data={candleData} 
                    rsiData={indicatorValues.rsi} 
                    timeframe={timeframe}
                    szDecimals={coinStats.szDecimals}
                    isSpot={coinStats.isSpot}
                    actualDecimals={coinStats.actualDecimals}
                  />
                )}
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Liquidity Swings ({timeframe})</h3>
                <LiquiditySwingsChart 
                  data={candleData}
                  timeframe={timeframe}
                  szDecimals={coinStats.szDecimals}
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Chaikin Money Flow ({timeframe})</h3>
                <ChaikinMoneyFlowChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Williams R Chart ({timeframe})</h3>
                <WilliamsRChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>
              
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Volume Chart ({timeframe})</h3>
                <VolumeChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot} 
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Channel Pattern ({timeframe})</h3>
                <ChannelPatternChart 
                  data={candleData} 
                  timeframe={timeframe}
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Harmonic Patterns ({timeframe})</h3>
                <HarmonicPatternsChart 
                  data={candleData}
                  timeframe={timeframe}
                  szDecimals={coinStats.szDecimals}
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>
              
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">MACD Chart ({timeframe})</h3>
                <MacdChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>
              
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">RSI Chart ({timeframe})</h3>
                <RsiChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot} 
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Stochastic RSI Chart ({timeframe})</h3>
                <StochRSIChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot} 
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Stochastic Oscillator ({timeframe})</h3>
                <StochasticChart 
                  data={candleData}
                  stochasticData={stochasticData}
                />
              </div>
              
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">DMI Chart ({timeframe})</h3>
                <DmiChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot} 
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">On-Balance Volume (OBV) ({timeframe})</h3>
                <OBVChart 
                  data={candleData}
                  obvData={obvData}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Average True Range (ATR) ({timeframe})</h3>
                <ATRChart 
                  data={candleData}
                  atrData={atrData}
                />
              </div>
              
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Bollinger Bands Chart ({timeframe})</h3>
                <BollingerBandsChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Ichimoku Cloud ({timeframe})</h3>
                <IchimokuCloudChart 
                  data={candleData}
                  ichimokuData={ichimokuData}
                />
              </div>
              
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Fibonacci Levels Chart ({timeframe})</h3>
                <FibonacciLevelsChart 
                  data={candleData} 
                  fibonacciLevels={indicatorValues.fibonacci || []} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>
              
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Support and Resistance Levels Chart ({timeframe})</h3>
                <SupportResistanceLevelsChart 
                  data={candleData} 
                  levels={indicatorValues.supportResistance || { support: [], resistance: [] }} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Consensus Chart ({timeframe})</h3>
                {consensusError ? (
                  <div className="text-red-500 p-4 bg-red-100 border border-red-400 rounded">
                    <h4 className="font-bold mb-2">Error:</h4>
                    <p>{consensusError}</p>
                  </div>
                ) : (
                  <ConsensusChart 
                    data={candleData}
                    indicators={indicatorValues}
                    volume={candleData.map(d => d.volume)}
                    timeframe={timeframe}
                    szDecimals={coinStats.szDecimals}
                    isSpot={coinStats.isSpot}
                    actualDecimals={coinStats.actualDecimals}
                    onError={handleConsensusError}
                    consensusSignals={consensusSignals}  // Add this line
                  />
                )}
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">VWAP/MA 5-200 Chart ({timeframe})</h3>
                <VwapMaChart 
                  data={candleData}
                  vwap={indicatorValues.vwap}
                  ma5={indicatorValues.ma?.ma5}
                  ma10={indicatorValues.ma?.ma10}
                  ma20={indicatorValues.ma?.ma20}
                  ma50={indicatorValues.ma?.ma50}
                  ma100={indicatorValues.ma?.ma100}
                  ma200={indicatorValues.ma?.ma200}
                  onHover={handleChartHover}
                  szDecimals={coinStats.szDecimals}
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
                <div className="mt-2 text-sm grid grid-cols-2 md:grid-cols-4 gap-2">
                  <p><span className="inline-block w-4 h-4 bg-red-500 mr-2"></span>VWAP {hoverValues ? `- ${hoverValues.vwap}` : ''}</p>
                  <p><span className="inline-block w-4 h-4 bg-green-500 mr-2"></span>MA5 {hoverValues ? `- ${hoverValues.ma5}` : ''}</p>
                  <p><span className="inline-block w-4 h-4 bg-blue-500 mr-2"></span>MA10 {hoverValues ? `- ${hoverValues.ma10}` : ''}</p>
                  <p><span className="inline-block w-4 h-4 bg-yellow-500 mr-2"></span>MA20 {hoverValues ? `- ${hoverValues.ma20}` : ''}</p>
                  <p><span className="inline-block w-4 h-4 bg-purple-500 mr-2"></span>MA50 {hoverValues ? `- ${hoverValues.ma50}` : ''}</p>
                  <p><span className="inline-block w-4 h-4 bg-cyan-500 mr-2"></span>MA100 {hoverValues ? `- ${hoverValues.ma100}` : ''}</p>
                  <p><span className="inline-block w-4 h-4 bg-gray-500 mr-2"></span>MA200 {hoverValues ? `- ${hoverValues.ma200}` : ''}</p>
                </div>
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">TD Sequential Chart ({timeframe})</h3>
                <TDSequentialChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Pivot Points ({timeframe})</h3>
                <PivotPointsChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Parabolic SAR ({timeframe})</h3>
                <ParabolicSARChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Commodity Channel Index - CCI ({timeframe})</h3>
                <CCIChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Keltner Channels Chart ({timeframe})</h3>
                <KeltnerChannelsChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Money Flow Index Chart ({timeframe})</h3>
                <MoneyFlowIndexChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">On Balance Volume Chart ({timeframe})</h3>
                <OnBalanceVolumeChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">FibonacciTimeZonesChart ({timeframe})</h3>
                <FibonacciTimeZonesChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Heikin Ashi Chart ({timeframe})</h3>
                <HeikinAshiChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Accumulation Distribution Line Chart ({timeframe})</h3>
                <AccumulationDistributionLineChart 
                  data={candleData} 
                  szDecimals={coinStats.szDecimals} 
                  isSpot={coinStats.isSpot}
                  actualDecimals={coinStats.actualDecimals}
                />
              </div>

            </>
          )}

          {activeTab === 'indicatorData' && (
            <>
              <IndicatorValues 
                indicatorValues={indicatorValues} 
                szDecimals={coinStats?.szDecimals || 0}
                isSpot={coinStats?.isSpot || false}
              />

              <div className="mb-8 bg-white shadow-md rounded p-4">
                <h3 className="text-xl font-semibold mb-2">VWAP/MA Levels</h3>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  <div>
                    <h4 className="text-sm font-semibold text-gray-500">VWAP</h4>
                    <p className="text-lg font-bold">
                      {getVwapMaStatus(candleData[candleData.length - 1]?.close, getLatestValue(indicatorValues.vwap))}
                    </p>
                    <p className="text-sm">
                      {formatNumber(getLatestValue(indicatorValues.vwap), coinStats.szDecimals === 0 ? coinStats.actualDecimals : coinStats.szDecimals)}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-sm font-semibold text-gray-500">MA5</h4>
                    <p className="text-lg font-bold">
                      {getVwapMaStatus(candleData[candleData.length - 1]?.close, getLatestValue(indicatorValues.ma?.ma5))}
                    </p>
                    <p className="text-sm">
                      {formatNumber(getLatestValue(indicatorValues.ma?.ma5), coinStats.szDecimals === 0 ? coinStats.actualDecimals : coinStats.szDecimals)}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-sm font-semibold text-gray-500">MA10</h4>
                    <p className="text-lg font-bold">
                      {getVwapMaStatus(candleData[candleData.length - 1]?.close, getLatestValue(indicatorValues.ma?.ma10))}
                    </p>
                    <p className="text-sm">
                      {formatNumber(getLatestValue(indicatorValues.ma?.ma10), coinStats.szDecimals === 0 ? coinStats.actualDecimals : coinStats.szDecimals)}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-sm font-semibold text-gray-500">MA20</h4>
                    <p className="text-lg font-bold">
                      {getVwapMaStatus(candleData[candleData.length - 1]?.close, getLatestValue(indicatorValues.ma?.ma20))}
                    </p>
                    <p className="text-sm">
                      {formatNumber(getLatestValue(indicatorValues.ma?.ma20), coinStats.szDecimals === 0 ? coinStats.actualDecimals : coinStats.szDecimals)}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-sm font-semibold text-gray-500">MA50</h4>
                    <p className="text-lg font-bold">
                      {getVwapMaStatus(candleData[candleData.length - 1]?.close, getLatestValue(indicatorValues.ma?.ma50))}
                      </p>
                      <p className="text-sm">
                        {formatNumber(getLatestValue(indicatorValues.ma?.ma50), coinStats.szDecimals === 0 ? coinStats.actualDecimals : coinStats.szDecimals)}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-sm font-semibold text-gray-500">MA100</h4>
                      <p className="text-lg font-bold">
                        {getVwapMaStatus(candleData[candleData.length - 1]?.close, getLatestValue(indicatorValues.ma?.ma100))}
                      </p>
                      <p className="text-sm">
                        {formatNumber(getLatestValue(indicatorValues.ma?.ma100), coinStats.szDecimals === 0 ? coinStats.actualDecimals : coinStats.szDecimals)}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-sm font-semibold text-gray-500">MA200</h4>
                      <p className="text-lg font-bold">
                        {getVwapMaStatus(candleData[candleData.length - 1]?.close, getLatestValue(indicatorValues.ma?.ma200))}
                      </p>
                      <p className="text-sm">
                        {formatNumber(getLatestValue(indicatorValues.ma?.ma200), coinStats.szDecimals === 0 ? coinStats.actualDecimals : coinStats.szDecimals)}
                      </p>
                    </div>
                  </div>
                </div>
  
                <FibonacciLevels 
                  levels={indicatorValues.fibonacci || []} 
                  szDecimals={coinStats.szDecimals}
                  isSpot={coinStats.isSpot}
                />
                <SupportResistanceLevels 
                  levels={indicatorValues.supportResistance || { support: [], resistance: [] }} 
                  szDecimals={coinStats.szDecimals}
                  isSpot={coinStats.isSpot}
                />
              </>
            )}
  
            {activeTab === 'aiAnalysis' && (
              <>
                <div className="mb-8 bg-white shadow-md rounded p-4">
                  <h3 className="text-xl font-semibold mb-2">Is is best to buy or sell?</h3>
                  <p className="text-lg">Based on current readings of the RSI, MACD, Bollinger Bands, Moving Averages (50, 200) and DMI. {marketAssessment}</p>
                </div>

                <div className="mb-8 bg-white shadow-md rounded p-4">
                  <h3 className="text-xl font-semibold mb-2">Comprehensive Technical Analysis</h3>
                  {combinedAnalysis ? (
                    <>
                      <p className="font-bold">Overall Sentiment: <span className={combinedAnalysis.sentiment === 'Bullish' ? 'text-green-600' : 'text-red-600'}>{combinedAnalysis.sentiment}</span></p>
                      <p>Confidence: {(combinedAnalysis.confidence * 100).toFixed(2)}%</p>
                      <ul className="list-disc pl-5 mt-2">
                        {combinedAnalysis.analysis.map((point, index) => (
                          <li key={index}>{point}</li>
                        ))}
                      </ul>
                      <p className="mt-4 italic">Note: This analysis combines multiple technical indicators and patterns. Always conduct your own research before making investment decisions.</p>
                    </>
                  ) : (
                    <p>Loading comprehensive analysis...</p>
                  )}
                </div>
  
                {aiSentiment && (
                  <AISentiment 
                    sentiment={aiSentiment} 
                    timeframe={timeframe} 
                  />
                )}
  
                <MacdCrossoverSignal 
                  macdCrossover={macdCrossover} 
                  timeframe={timeframe}
                />
              </>
            )}
          </>
        ) : (
          <div className="text-center">Loading chart data...</div>
        )}
        </>
      )}
      </div>
    );
  };
  
  export default CoinDetails;